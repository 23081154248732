import isEmpty from 'lodash/isEmpty';
import React from 'react';

import useMedia from '../../utils/useMedia';
import { ContentCollection } from '../NavSearchElements/NavSearchTypes';
import AutoSuggestDropdown, { AutoSuggestDropdownProps } from './AutoSuggestDropdown';
import CollectionsSuggestion, { CollectionsSuggestionProps } from './CollectionsSuggestion';
import { NullStateSuggestionTypes } from './constants';

export type NullSuggestions = {
    latestCollections?: ContentCollection[];
    trendingCollections?: ContentCollection[];
};

export interface NullSearchDropdownProps {
    showSuggestions?: boolean;
    suggestions?: NullSuggestions;
    autoSuggestDropdownProps?: Omit<AutoSuggestDropdownProps, 'show'>;
    latestCollectionsSuggestionProps?: Omit<CollectionsSuggestionProps, 'collections'>;
    trendingCollectionsSuggestionProps?: Omit<CollectionsSuggestionProps, 'collections'>;
    useNewSearchUI?: boolean;
}
const NullSearchDropdown = ({
    showSuggestions = false,
    suggestions = {
        latestCollections: [],
        trendingCollections: [],
    },
    autoSuggestDropdownProps = {},
    latestCollectionsSuggestionProps = {},
    trendingCollectionsSuggestionProps = {},
    useNewSearchUI,
}: NullSearchDropdownProps) => {
    const isLargeScreen = useMedia('(min-width: 1024px)', true);
    const numberItemsToShow = useNewSearchUI && !isLargeScreen ? 2 : 3;

    return (
        <AutoSuggestDropdown
            show={showSuggestions}
            className={
                useNewSearchUI
                    ? 'flex flex-col py-4 px-6 space-y-7'
                    : 'flex flex-col py-4 px-6 space-y-7 mt-2.5 -ml-2.5'
            }
            {...autoSuggestDropdownProps}
            useNewSearchUI={useNewSearchUI}
        >
            {!isEmpty(suggestions?.latestCollections) && (
                <CollectionsSuggestion
                    collections={suggestions.latestCollections}
                    title="Latest Collections"
                    numberItemsToShow={numberItemsToShow}
                    showSeeMoreLink={false}
                    className="p-0"
                    titleClassName="w-1/2"
                    getTelemetryString={(routeUri) => `typeahead.null.collectionNew.${routeUri}`}
                    idLabel={`${NullStateSuggestionTypes.latestCollections}`}
                    {...latestCollectionsSuggestionProps}
                />
            )}
            {!isEmpty(suggestions?.trendingCollections) && (
                <CollectionsSuggestion
                    collections={suggestions.trendingCollections}
                    title="Trending Collections"
                    numberItemsToShow={numberItemsToShow}
                    className="p-0 space-y-3"
                    titleClassName="w-1/2"
                    getTelemetryString={(routeUri) => `typeahead.null.collectionTrending.${routeUri}`}
                    idLabel={`${NullStateSuggestionTypes.trendingCollections}`}
                    {...trendingCollectionsSuggestionProps}
                />
            )}
        </AutoSuggestDropdown>
    );
};

export default NullSearchDropdown;
