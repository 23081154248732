import React, { useEffect, useState } from 'react';

// i.e. https://github.com/streamich/react-use/blob/master/src/useMedia.ts
// with better SSR support (check !!window.matchMedia)

const isBrowser = typeof window !== 'undefined';
const noop = () => {
    return undefined;
};

const getInitialState = (query: string, defaultState?: boolean) => {
    // Prevent a React hydration mismatch when a default value is provided by not defaulting to window.matchMedia(query).matches.
    if (defaultState !== undefined) {
        return defaultState;
    }

    if (isBrowser) {
        return window.matchMedia(query).matches;
    }

    // A default value has not been provided, and you are rendering on the server, warn of a possible hydration mismatch when defaulting to false.
    if (process.env.NODE_ENV !== 'production') {
        console.warn(
            '`useMedia` When server side rendering, defaultState should be defined to prevent a hydration mismatches.',
        );
    }

    return false;
};

const stubMatchMedia: Pick<MediaQueryList, 'matches' | 'addEventListener' | 'removeEventListener'> = {
    matches: false,
    addEventListener: noop,
    removeEventListener: noop,
};

export const useMedia = (query: string, defaultState?: boolean) => {
    const [state, setState] = useState(getInitialState(query, defaultState));

    useEffect(() => {
        let mounted = true;
        const mql = typeof window.matchMedia === 'function' ? window.matchMedia(query) : stubMatchMedia;

        const onChange = () => {
            if (!mounted) {
                return;
            }
            setState(!!mql.matches);
        };

        mql.addEventListener('change', onChange);
        setState(mql.matches);

        return () => {
            mounted = false;
            mql.removeEventListener('change', onChange);
        };
    }, [query]);

    return state;
};

export interface WithUseMediaProps {
    mediaQuery: boolean;
}

// https://react-typescript-cheatsheet.netlify.app/docs/hoc/full_example/
export function withUseMedia<T = WithUseMediaProps>(Component: React.ComponentType<T>, { query, defaultState }) {
    const displayName = Component.displayName || Component.name || 'withUseMedia';

    const ComponentWithMediaQuery = (props: Omit<T, keyof WithUseMediaProps>) => {
        const mediaQuery = useMedia(query, defaultState);

        return <Component mediaQuery={mediaQuery} {...(props as T)} />;
    };

    ComponentWithMediaQuery.displayName = `withUseMedia(${displayName})`;

    return ComponentWithMediaQuery;
}

export default useMedia;
