export const keyboardEventsKey = {
    ARROW_UP: 'ArrowUp' as const,
    ARROW_DOWN: 'ArrowDown' as const,
    ARROW_LEFT: 'ArrowLeft' as const,
    ARROW_RIGHT: 'ArrowRight' as const,
    ENTER: 'Enter' as const,
    ESCAPE: 'Escape' as const,
};

export const KeyboardDirectionsConst = [
    keyboardEventsKey.ARROW_UP,
    keyboardEventsKey.ARROW_DOWN,
    keyboardEventsKey.ARROW_LEFT,
    keyboardEventsKey.ARROW_RIGHT,
] as const;

export type KeyboardDirectionsType = (typeof KeyboardDirectionsConst)[number];

export const ListDirections = {
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical',
};

const ListDirectionsConst = [ListDirections.HORIZONTAL, ListDirections.VERTICAL] as const;

export type ListDirectionsType = (typeof ListDirectionsConst)[number];

export enum NullStateSuggestionTypes {
    latestCollections = 'latestCollections',
    trendingCollections = 'trendingCollections',
}

export const suggestionTypesKey = {
    KEYWORDS: 'keywords',
    WITHIN_ASSET: 'withinAssetContentTypeSuggestions',
    RELATED_CONTENT_TYPE: 'relatedContentTypeSuggestion',
    RELATED_COLLECTION: 'relatedCollections',
    SUGGESTED_PAGES: 'suggestedPages',
};

const SuggestionTypesConst = [
    suggestionTypesKey.KEYWORDS,
    suggestionTypesKey.WITHIN_ASSET,
    suggestionTypesKey.RELATED_CONTENT_TYPE,
    suggestionTypesKey.RELATED_COLLECTION,
    suggestionTypesKey.SUGGESTED_PAGES,
];

export type suggestionTypes = (typeof SuggestionTypesConst)[number];

export const ADOBE_PLUGIN_MARKETPLACE_URL = 'https://exchange.adobe.com/apps/cc/108662/storyblocks';

export const ADOBE_PLUGIN_LANDING_PAGE_RELATIVE_PATH = '/adobe-creative-cloud-plugin';
