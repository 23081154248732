import { ContentClass } from '../types/shared';

export const isCollectionItemNew = (collectionItem) => {
    if (!collectionItem || !collectionItem.dateAdded) {
        return false;
    }
    const dateAdded = collectionItem.dateAdded;
    const utcDateAdded = new Date(dateAdded);
    const millisecondsInASecond = 1000;
    const secondsInADay = 86400;
    const dateToNumber = (date = new Date()): number => Date.parse(String(date));

    const getDaysSinceDateAdded = Math.floor(
        Math.abs(dateToNumber(utcDateAdded) - dateToNumber()) / millisecondsInASecond / secondsInADay,
    );

    return getDaysSinceDateAdded < 100;
};

export function urlSegmentForContentClass(contentClass: ContentClass): string | null {
    switch (contentClass) {
        case ContentClass.Video:
            return 'video';
        case ContentClass.Audio:
            return 'audio';
        case ContentClass.Image:
            return 'images';
        default:
            return null;
    }
}

/**
 * Defers the given task to run after the next frame is rendered.
 * This helper allows us to punt potentially taxing logic that might otherwise
 * cause the current frame to get delayed.
 *
 * Duplicated from StockBlocks.
 *
 * @see https://web.dev/articles/optimize-inp#yield_to_allow_rendering_work_to_occur_sooner
 * @param callback
 */
export default function deferToNextFrame(callback: () => void): void {
    requestAnimationFrame(() => {
        setTimeout(callback, 0);
    });
}
