import React from 'react';
import { SearchFilterContentTypes } from 'stockblocks-client';

import { ContentClass, ContentType } from '../../types/shared';
import { WithinAssetSuggestions } from '../AutosuggestElements/WithinAssetSuggestion';
import { Paths } from './NavSearchConstants';

export type Keyword = string;

type ContentCollectionView = {
    routeUri?: string;
    redirectUri?: string;
    heroBgImgUrl?: string;
    heroTitle?: string;
    heroSubtitle?: string;
    description?: string;
    metaTitle?: string;
    metaDescription?: string;
    metaKeywords?: string;
    leftXsellTitle?: string;
    leftXsellLinkTitle?: string;
    leftXsellLinkUrl?: string;
    leftXsellBgImgUrl?: string;
    rightXsellTitle?: string;
    rightXsellLinkTitle?: string;
    rightXsellLinkUrl?: string;
    rightXsellBgImgUrl?: string;
    allowSeoIndex?: boolean;
    showInDirectory?: boolean;
    showInMainNavDropdown?: boolean;
    directoryBackgroundImageUrl?: string;
};

export type ContentCollection = {
    active?: boolean;
    dateAdded?: string;
    dateUpdated?: string;
    description?: string;
    id?: number;
    name?: string;
    searchIndexable?: boolean;
    showInApi?: boolean;
    contentClasses?: ContentClass[];
    view: ContentCollectionView;
};

export type TypeAheadSuggestedPage = {
    terms: Keyword[];
    title: string;
    routeUri: Paths;
    directoryBackgroundImage: () => React.ReactElement;
    shouldShowForEnterprise?: boolean;
};

// confirm/assert obj is TypeAheadSuggestedPage
// https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
export function isTypeAheadSuggestedPage(
    obj: TypeAheadSuggestedPage | ContentCollection | string,
): obj is TypeAheadSuggestedPage {
    return (obj as TypeAheadSuggestedPage)?.title !== undefined;
}

export const isTypeaheadKeyword = (obj: TypeAheadSuggestedPage | ContentCollection | string): boolean => {
    return typeof obj === 'string';
};

export interface Suggestions {
    keywords: string[];
    collections: ContentCollection[];
    relatedPages: TypeAheadSuggestedPage[];
}

export type relatedContentTypeAndKeyword = {
    contentClass: string;
    contentType: string;
    keywords: string | string[];
};

export const searchFilterContentTypesToRelatedContentType = {
    [SearchFilterContentTypes.All_videos_content_type]: [ContentType.Music, ContentType.Sound_effect],
    [SearchFilterContentTypes.Footage]: [
        ContentType.Motion_bg,
        ContentType.Template,
        ContentType.Music,
        ContentType.Sound_effect,
    ],
    [SearchFilterContentTypes.Templates]: [
        ContentType.Footage,
        ContentType.Motion_bg,
        ContentType.Music,
        ContentType.Sound_effect,
    ],
    [SearchFilterContentTypes.Motion_bgs]: [
        ContentType.Footage,
        ContentType.Template,
        ContentType.Music,
        ContentType.Sound_effect,
    ],

    [SearchFilterContentTypes.All_audio_content_type]: [
        ContentType.Footage,
        ContentType.Motion_bg,
        ContentType.Template,
    ],
    [SearchFilterContentTypes.Music]: [
        ContentType.Sound_effect,
        ContentType.Footage,
        ContentType.Motion_bg,
        ContentType.Template,
    ],
    [SearchFilterContentTypes.Sound_effects]: [
        ContentType.Music,
        ContentType.Footage,
        ContentType.Motion_bg,
        ContentType.Template,
    ],

    [SearchFilterContentTypes.All_images_content_type]: [
        ContentType.Footage,
        ContentType.Motion_bg,
        ContentType.Template,
    ],
    [SearchFilterContentTypes.Photos]: [
        ContentType.Vector,
        ContentType.Illustration,
        ContentType.Footage,
        ContentType.Motion_bg,
        ContentType.Template,
    ],
    [SearchFilterContentTypes.Vectors]: [
        ContentType.Photo,
        ContentType.Illustration,
        ContentType.Footage,
        ContentType.Motion_bg,
        ContentType.Template,
    ],
    [SearchFilterContentTypes.Illustrations]: [
        ContentType.Vector,
        ContentType.Photo,
        ContentType.Footage,
        ContentType.Motion_bg,
        ContentType.Template,
    ],
};

export const RelatedContentTypeLabel = {
    [ContentType.Footage]: 'Footage',
    [ContentType.Template]: 'Templates',
    [ContentType.Music]: 'Music',
    [ContentType.Motion_bg]: 'Animated Backgrounds',
    [ContentType.Vr_360]: 'vr 360',
    [ContentType.Vector]: 'Vectors',
    [ContentType.Photo]: 'Photos',
    [ContentType.Sound_effect]: 'Sound Effects',
    [ContentType.Illustration]: 'Illustrations',
};

export const WithinContentClassLabel = {
    [ContentClass.Video]: 'Video',
    [ContentClass.Image]: 'Image',
    [ContentClass.Audio]: 'Audio',
};

export const searchFilterContentTypeToContentClass = {
    [SearchFilterContentTypes.All_videos_content_type]: ContentClass.Video,
    [SearchFilterContentTypes.All_audio_content_type]: ContentClass.Audio,
    [SearchFilterContentTypes.All_images_content_type]: ContentClass.Image,
};

export const searchFilterContentTypesToWithinContentType = {
    [SearchFilterContentTypes.All_videos_content_type]: [
        ContentType.Footage,
        ContentType.Motion_bg,
        ContentType.Template,
    ],
    [SearchFilterContentTypes.All_audio_content_type]: [ContentType.Music, ContentType.Sound_effect],
    [SearchFilterContentTypes.All_images_content_type]: [
        ContentType.Photo,
        ContentType.Vector,
        ContentType.Illustration,
    ],
};

export const contentTypeToSearchFilterContentType = {
    [ContentType.Footage]: SearchFilterContentTypes.Footage,
    [ContentType.Motion_bg]: SearchFilterContentTypes.Motion_bgs,
    [ContentType.Template]: SearchFilterContentTypes.Templates,
    [ContentType.Music]: SearchFilterContentTypes.Music,
    [ContentType.Sound_effect]: SearchFilterContentTypes.Sound_effects,
    [ContentType.Photo]: SearchFilterContentTypes.Photos,
    [ContentType.Vector]: SearchFilterContentTypes.Vectors,
    [ContentType.Illustration]: SearchFilterContentTypes.Illustrations,
};

export const computeWithinAssetSuggestions = (
    relatedContentTypesAndKeywords: relatedContentTypeAndKeyword[] = [],
    selectedContentType: SearchFilterContentTypes,
): WithinAssetSuggestions => {
    if (!searchFilterContentTypeToContentClass[selectedContentType]) {
        return {
            contentClass: '',
            withinAssetContentTypeSuggestions: [],
        };
    }

    const withinContentTypeToKeywordsMap = relatedContentTypesAndKeywords.reduce((acc, el) => {
        if (el.contentType) {
            return {
                ...acc,
                [el.contentType]: el.keywords,
            };
        }
        return acc;
    }, {});

    const withinContentTypes = searchFilterContentTypesToWithinContentType[selectedContentType];

    const withinAssetContentTypeSuggestions = (withinContentTypes || []).reduce((acc, contentType) => {
        if (withinContentTypeToKeywordsMap[contentType] && withinContentTypeToKeywordsMap[contentType].length > 0) {
            return [
                ...acc,
                {
                    keyword: withinContentTypeToKeywordsMap[contentType][0],
                    contentType,
                },
            ];
        }

        return acc;
    }, []);

    return {
        contentClass: searchFilterContentTypeToContentClass[selectedContentType],
        withinAssetContentTypeSuggestions,
    };
};

export const computeRelatedContentTypeSuggestion = (
    relatedContentTypesAndKeywords: relatedContentTypeAndKeyword[] = [],
    selectedContentType: SearchFilterContentTypes,
) => {
    const relatedContentTypeToKeywordsMap = relatedContentTypesAndKeywords.reduce((acc, el) => {
        if (el.contentType) {
            return {
                ...acc,
                [el.contentType]: el.keywords,
            };
        }
        return acc;
    }, {});
    const relatedContentTypes = searchFilterContentTypesToRelatedContentType[selectedContentType];

    return (relatedContentTypes || []).reduce((acc, contentType) => {
        if (relatedContentTypeToKeywordsMap[contentType] && relatedContentTypeToKeywordsMap[contentType].length > 0) {
            return [
                ...acc,
                {
                    keyword: relatedContentTypeToKeywordsMap[contentType][0],
                    contentType,
                },
            ];
        }

        return acc;
    }, []);
};
