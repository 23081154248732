import noop from 'lodash/noop';
import React, { useRef } from 'react';
import Dropdown from 'react-dropdown';

import { ChevronUp, ChevronDown } from '@videoblocks/react-icons';

import { ContentTypes, ContentTypesWithSnapshots } from '../NavSearchElements/ContentTypes';

import '../NavSearchElements/navSearchDropdown.css';

export default function ProminentSearchDropdown({
    onChange = noop,
    value = '',
    useNewSearchUI,
    useSnapshots,
    clearSuggestions = noop,
}: {
    onChange: (string) => void;
    value: string;
    useNewSearchUI?: boolean;
    useSnapshots?: boolean;
    clearSuggestions: () => void;
}) {
    const dropdownButtonRef = useRef<HTMLSpanElement>(null);
    const handleChange = (event) => {
        const contentType = event.value;
        onChange(contentType);
    };

    const dropdownClick = () => {
        if (dropdownButtonRef.current) {
            dropdownButtonRef.current.focus();
        }
    };

    const options = useSnapshots ? ContentTypesWithSnapshots : ContentTypes;

    if (useNewSearchUI) {
        // tabIndex and role are required to make span focusable, onClick forces focus state, onKeyDown is just to appease eslint
        return (
            <span
                tabIndex={0}
                onKeyDown={undefined}
                role={'button'}
                onClick={dropdownClick}
                ref={dropdownButtonRef}
                className={
                    'newSearchUnifiedNav-searchDropdown hover:bg-gray-900 rounded-full text-base h-14 m-2 p-4 text-white'
                }
            >
                <Dropdown
                    aria-label="Select Search Type"
                    onChange={handleChange}
                    onFocus={clearSuggestions}
                    options={options}
                    placeholder="Select..."
                    value={value}
                    arrowClosed={<ChevronDown className="w-4 h-4 m-1.5 pointer-events-none" />}
                    arrowOpen={<ChevronUp className="w-4 h-4 m-1.5 pointer-events-none" />}
                />
            </span>
        );
    }

    return (
        <span className={'unifiedNav-searchDropdown unifiedNav-searchDropdown-experiment'}>
            <Dropdown
                aria-label="Select Search Type"
                onChange={handleChange}
                options={options}
                placeholder="Select..."
                value={value}
                arrowClosed={<ChevronDown className="w-4 h-4 m-1.5" />}
                arrowOpen={<ChevronUp className="w-4 h-4 m-1.5" />}
            />
        </span>
    );
}
