import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import CloseIcon from '@videoblocks/react-icons/Close';

interface RenderInputComponentProps extends React.InputHTMLAttributes<any> {
    defaultValue?: string;
    useNewSearchUI?: boolean;
    showClearButton?: boolean;
    onClearSearchTerm?: (event: any) => void;
}

const AutoSuggestInput = React.forwardRef<HTMLInputElement, RenderInputComponentProps>(
    (
        {
            defaultValue,
            className,
            placeholder = 'Search...',
            useNewSearchUI,
            showClearButton,
            onClearSearchTerm,
            onFocus,
            onBlur,
            ...rest
        }: RenderInputComponentProps,
        ref,
    ) => {
        const [dynamicPlaceholder, setDynamicPlaceholder] = useState(placeholder);

        if (useNewSearchUI) {
            return (
                <div className="flex flex-row items-center">
                    <input
                        id="search-input"
                        type="text"
                        autoComplete="off"
                        ref={ref}
                        aria-label="Input Search: "
                        data-public={true}
                        placeholder={dynamicPlaceholder}
                        className={twMerge(
                            'unifiedNav-searchInput rounded-l-xl-35 border-none shadow-none font-semibold h-full outline-none w-full text-lg px-3',
                            className,
                        )}
                        defaultValue={defaultValue}
                        onFocus={(e) => {
                            setDynamicPlaceholder('');
                            onFocus?.(e);
                        }}
                        onBlur={(e) => {
                            setDynamicPlaceholder(placeholder);
                            onBlur?.(e);
                        }}
                        {...rest}
                    />
                    <div className="p-4">
                        <button
                            id="clear-search-term-button"
                            type="button"
                            onClick={onClearSearchTerm}
                            className={showClearButton ? 'w-4 h-4 border-none' : 'invisible'}
                        >
                            <CloseIcon />
                        </button>
                    </div>
                </div>
            );
        }
        return (
            <input
                autoComplete="off"
                ref={ref}
                aria-label="Input Search: "
                data-public={true}
                placeholder={placeholder}
                className={twMerge(
                    'unifiedNav-searchInput rounded-l-xl-35 border-none shadow-none font-semibold h-full outline-none w-full py-2 text-sm px-3',
                    className,
                )}
                defaultValue={defaultValue}
                onFocus={onFocus}
                onBlur={onBlur}
                {...rest}
            />
        );
    },
);

AutoSuggestInput.displayName = 'AutoSuggestInput';

export default AutoSuggestInput;
