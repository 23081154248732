import React, { useContext, useRef } from 'react';
import { SearchFilterContentTypes } from 'stockblocks-client';
import { useDebouncedCallback } from 'use-debounce';

import { NavSearchContext } from '../../context/NavSearchContext';
import { CrossAssetNavSuggestions } from '../../types/shared';
import fetchTypeahead from '../../utils/fetchTypeahead';
import AutoSuggest from '../AutosuggestElements/AutoSuggest';
import { validateKeywords } from './searchUtils';

interface CrossAssetNavSearchBoxProps {
    contentType: SearchFilterContentTypes;
    setSearchTerm: (value: string) => void;
    onSubmit: (event: string, value: string, searchOrigin: string) => void;
    searchTerm: string;
    useNewSearchUI?: boolean;
    placeholder?: string;
    suggestions: any;
    setSuggestions: ({}: Partial<CrossAssetNavSuggestions>) => void;
    clearSuggestions: () => void;
}

export default function CrossAssetNavSearchBox({
    contentType,
    setSearchTerm,
    onSubmit,
    searchTerm,
    useNewSearchUI,
    suggestions,
    setSuggestions,
    clearSuggestions,
    placeholder,
}: CrossAssetNavSearchBoxProps) {
    const context = useContext(NavSearchContext);

    const { showSuggestions, setShowSuggestions } = context;
    const showSuggestionsRef = useRef(showSuggestions);
    // Keep track so we know when suggestions are fetched whether we actually want to render them.
    showSuggestionsRef.current = showSuggestions;

    const loadSuggestions = async (value) => {
        const data = await fetchTypeahead(contentType, value, true);
        if (!showSuggestionsRef.current) {
            clearSuggestions();
            return;
        }

        if (!validateKeywords(value)) {
            // when keywords are less than 2 characters, render null state search component
            setSuggestions({
                keywords: [],
                relatedCollections: [],
                relatedContentTypesAndKeywords: [],
                latestCollections: data?.latestCollections || [],
                trendingCollections: data?.collections || [],
            });
        } else {
            setSuggestions({
                keywords: data?.keywords || '',
                relatedCollections: data?.collections || [],
                relatedContentTypesAndKeywords: data?.relatedContentTypesAndKeywords || [],
                latestCollections: [],
                trendingCollections: [],
            });
        }
    };

    const handleSuggestionSelected = (data, contentTypeLocal = '', searchOriginOverride = '') => {
        setShowSuggestions(false);
        onSubmit(contentTypeLocal || contentType, data, searchOriginOverride);
    };

    const handleSuggestionsFetchRequested = useDebouncedCallback(loadSuggestions, 250, { maxWait: 1000 });

    const showSuggestionsOnSearchTermChange = (value) => {
        setSearchTerm(value);
        setShowSuggestions(true);
        handleSuggestionsFetchRequested(value);
    };

    return (
        <div
            className={
                useNewSearchUI
                    ? 'unifiedNav-searchBox text-gray-900 w-full h-full justify-start items-center gap-1 inline-flex'
                    : 'unifiedNav-searchBox w-full h-full justify-start items-center gap-1 inline-flex'
            }
        >
            <AutoSuggest
                suggestions={suggestions}
                placeholder={placeholder}
                searchTerm={searchTerm}
                showSuggestionsOnSearchTermChange={showSuggestionsOnSearchTermChange}
                setSearchTerm={setSearchTerm}
                onSelectSuggestions={handleSuggestionSelected}
                onClearSuggestions={clearSuggestions}
                contentType={contentType as SearchFilterContentTypes}
                useNewSearchUI={useNewSearchUI}
            />
        </div>
    );
}
