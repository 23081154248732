import { incrementTelemetry } from 'core-events';
import React, { useContext } from 'react';
import { twMerge } from 'tailwind-merge';

import { NavSearchContext } from '../../context/NavSearchContext';
import { ContentClass } from '../../types/shared';
import { isCollectionItemNew } from '../../utils/shared';
import CollectionCard from '../CollectionCard';
import { ContentCollection } from '../NavSearchElements/NavSearchTypes';
import { interactiveElements } from './utils';

export interface CollectionsSuggestionProps {
    collections?: ContentCollection[];
    title?: string;
    collectionCardContainerClassName?: string;
    showSeeMoreLink?: boolean;
    className?: string;
    titleClassName?: string;
    relatedCollections?: ContentCollection[];
    numberItemsToShow?: number;
    seeMoreHref?: string;
    getTelemetryString?: (routeUri) => string;
    selectedIndex?: number;
    seeMoreLinkFocused?: boolean;
    idLabel?: string;
    useNewSearchUI?: boolean;
}

export const determineCollectionLinkContentClass = (contentClasses: ContentClass[] = []) => {
    if (contentClasses?.length === 0 || contentClasses.includes(ContentClass.Video)) {
        return ContentClass.Video;
    }

    if (
        contentClasses.length === 2 &&
        contentClasses[0] === ContentClass.Audio &&
        contentClasses[1] === ContentClass.Image
    ) {
        return ContentClass.Image;
    }

    return contentClasses[0];
};

const CollectionsSuggestion = ({
    collections = [],
    numberItemsToShow = 2,
    seeMoreHref = '/video/collections',
    title = '',
    collectionCardContainerClassName = '',
    showSeeMoreLink = true,
    className = '',
    titleClassName = '',
    getTelemetryString = () => '',
    selectedIndex = -1,
    seeMoreLinkFocused = false,
    idLabel = '',
    useNewSearchUI,
}: CollectionsSuggestionProps) => {
    const context = useContext(NavSearchContext);

    return (
        !!collections?.length && (
            <div className={twMerge('flex flex-col w-full space-y-2.5', className)}>
                <div
                    className={twMerge(
                        useNewSearchUI
                            ? 'related-collection-title text-xs border-gray-300 border-0 border-b w-full lg:min-w-38 min-w-21vw border-solid leading-5 font-normal'
                            : 'related-collection-title text-sm border-gray-500 border-0 border-b w-3/4 lg:min-w-38 min-w-21vw border-solid leading-5 font-normal',
                        titleClassName,
                    )}
                >
                    {title}
                </div>
                <ul className="flex space-x-3 list-none" role="listbox">
                    {collections.slice(0, numberItemsToShow).map((collection, index) => {
                        const { view, contentClasses = [] } = collection;
                        const { heroTitle, routeUri, directoryBackgroundImageUrl } = view;
                        const collectionLinkContentClass = determineCollectionLinkContentClass(contentClasses);
                        const shouldDisplayNewLabel = isCollectionItemNew(collection);

                        return (
                            <li
                                className={twMerge(
                                    `lg:min-w-38 min-w-21vw flex flex-col space-y-2`,
                                    collectionCardContainerClassName,
                                )}
                                key={index}
                                id={`${idLabel}${index}`}
                            >
                                <CollectionCard
                                    className={`${index === selectedIndex && 'border-solid border-2 border-blue-500'}`}
                                    label={heroTitle || ''}
                                    href={routeUri || ''}
                                    thumbnailSrc={directoryBackgroundImageUrl || ''}
                                    thumbnailAlt={directoryBackgroundImageUrl || ''}
                                    labelClassName="text-xs px-2 pb-2.5 pt-0.5"
                                    iconContentClasses={contentClasses}
                                    collectionLinkContentClass={collectionLinkContentClass}
                                    showNewLabel={shouldDisplayNewLabel}
                                    newLabelClassName="top-2 left-2"
                                    onClick={() => {
                                        const telemetryString = getTelemetryString(routeUri);
                                        if (telemetryString) {
                                            incrementTelemetry(telemetryString);
                                        }
                                    }}
                                    imagesUrl={context.imagesUrl}
                                />
                            </li>
                        );
                    })}
                </ul>
                {showSeeMoreLink && collections.slice(0, numberItemsToShow).length > 0 && (
                    <div
                        id={`${interactiveElements.SeeMoreCollectionLink}-1`}
                        className={`flex items-center space-x-2 justify-end ${
                            seeMoreLinkFocused && 'border-solid border-2 border-blue-500'
                        }`}
                    >
                        <a
                            href={seeMoreHref}
                            className="text-sm text-gray-700 font-normal"
                            onClick={() => incrementTelemetry('typeahead.viewAllCollections.click')}
                        >
                            See More
                        </a>
                        <svg width="14" height="6" viewBox="0 0 14 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M14 3L10 0V2L0 2V4L10 4V6L14 3Z"
                                fill="#626C78"
                            />
                        </svg>
                    </div>
                )}
            </div>
        )
    );
};

export default CollectionsSuggestion;
