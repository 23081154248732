import { incrementTelemetry } from 'core-events';
import noop from 'lodash/noop';
import React, { useContext } from 'react';
import { SearchFilterContentTypes } from 'stockblocks-client';
import { twMerge } from 'tailwind-merge';

import { NavSearchContext } from '../../context/NavSearchContext';
import { contentTypeToSearchFilterContentType, RelatedContentTypeLabel } from '../NavSearchElements/NavSearchTypes';
import { suggestionTypesKey } from './constants';

export type RelatedContentTypeSuggestion = {
    keyword: string;
    contentType: string;
};

export interface RelatedContentSuggestionProps {
    titleClassName?: string;
    title?: string;
    relatedContentTypeSuggestions?: RelatedContentTypeSuggestion[];
    onSelect?: (value: string, contentType?: SearchFilterContentTypes) => void;
    selectedIndex?: number;
    useNewSearchUI?: boolean;
}

export type relatedContentTypeAndKeywordFromResponse = {
    contentClass: string;
    contentType: string;
    keywords: string[];
};

const RelatedContentSuggestion = ({
    titleClassName = '',
    title = 'Related Content',
    relatedContentTypeSuggestions = [],
    onSelect = noop,
    selectedIndex = -1,
    useNewSearchUI,
}: RelatedContentSuggestionProps) => {
    const context = useContext(NavSearchContext);
    return (
        !!relatedContentTypeSuggestions?.length && (
            <div className="flex flex-col space-y-2.5">
                <div
                    className={twMerge(
                        useNewSearchUI
                            ? 'related-collection-title text-xs border-gray-300 border-0 border-b w-full lg:min-w-38 min-w-21vw border-solid leading-5 font-normal'
                            : 'related-collection-title text-sm border-gray-500 border-0 border-b w-3/4 lg:min-w-38 min-w-21vw border-solid leading-5 font-normal',
                        titleClassName,
                    )}
                >
                    {title}
                </div>
                <ul className={'flex flex-col min-w-31'}>
                    {relatedContentTypeSuggestions.map((suggestion, index) => {
                        const clickHandler = () => {
                            incrementTelemetry(`typeahead.relatedContent.click`);
                            onSelect(suggestion.keyword, contentTypeToSearchFilterContentType[suggestion.contentType]);
                        };

                        return (
                            <li
                                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                                role="button"
                                tabIndex={0}
                                id={`${suggestionTypesKey.RELATED_CONTENT_TYPE}${index}`}
                                key={index}
                                className={twMerge(
                                    'cursor-pointer text-sm leading-4 list-none whitespace-nowrap py-2 hover:bg-gray-110 font-normal',
                                    useNewSearchUI && '-ml-2 pl-2 rounded-md',
                                    index === selectedIndex && useNewSearchUI && 'bg-gray-110',
                                    index === selectedIndex &&
                                        !useNewSearchUI &&
                                        'bg-gray-110 border-solid border-2 border-blue-500',
                                )}
                                onClick={clickHandler}
                                onKeyDown={clickHandler}
                            >
                                {useNewSearchUI && (
                                    <svg
                                        width="8"
                                        height="10"
                                        viewBox="0 0 8 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M5.16481 9.77344L7.90348 7.46021L5.16481 5.14699V6.88634H0L0 8.03408H5.16481V9.77344Z"
                                            fill="black"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M3.11136e-05 -1.04904e-05L3.11136e-05 7.46027H1.14777L1.14777 -1.04904e-05H3.11136e-05Z"
                                            fill="black"
                                        />
                                    </svg>
                                )}
                                <span className="font-bold pl-2">{suggestion.keyword}</span>{' '}
                                {`in ${RelatedContentTypeLabel[suggestion.contentType]}`}
                            </li>
                        );
                    })}
                </ul>
            </div>
        )
    );
};

export default RelatedContentSuggestion;
