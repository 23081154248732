import { SearchFilterContentTypes } from 'stockblocks-client';

export const config = {
    name: 'Homepage Prominent Search',
    inputs: [
        {
            name: 'headingFirstLine',
            type: 'string',
            required: true,
            defaultValue: 'Tell all your best stories of the',
        },
        {
            name: 'headingSecondLine',
            type: 'string',
            required: true,
            defaultValue: 'world. Lorem ipsum dolor sit',
        },
        {
            name: 'trendingTags',
            type: 'string',
            required: true,
            defaultValue: 'Documentary,Love,Sport',
            helperText: 'Comma-separated list of trending tags',
        },
        {
            name: 'contentTypes',
            type: 'list',
            required: true,
            helperText: 'List of clickable content types / options',
            defaultValue: [
                {
                    id: SearchFilterContentTypes.All_videos_content_type,
                    label: 'Video',
                    placeholderText: 'Search video library...',
                    videoSrc: '/home/video-homepage-hero-background.webm',
                    posterSrc: '/home/video-homepage-hero-background.jpg',
                },
                {
                    id: SearchFilterContentTypes.Templates,
                    label: 'Video Templates',
                    placeholderText: 'Search templates library...',
                    videoSrc: '/home/template-homepage-hero-background.webm',
                },
                {
                    id: SearchFilterContentTypes.All_audio_content_type,
                    label: 'Audio',
                    placeholderText: 'Search audio library...',
                    videoSrc: '/home/audio-homepage-hero-background.webm',
                },
                {
                    id: SearchFilterContentTypes.All_images_content_type,
                    label: 'Images',
                    placeholderText: 'Search image library...',
                    videoSrc: '/home/image-homepage-hero-background.webm',
                },
            ],
            subFields: [
                {
                    name: 'id',
                    type: 'string',
                    required: true,
                    enum: Object.values(SearchFilterContentTypes),
                    defaultValue: SearchFilterContentTypes.All_videos_content_type,
                    helperText: 'URL root',
                },
                {
                    name: 'label',
                    type: 'string',
                    required: true,
                    defaultValue: '[Label]',
                    helperText: 'Text label for content type',
                },
                {
                    name: 'placeholderText',
                    type: 'string',
                    required: true,
                    defaultValue: 'Search [CONTENT] library...',
                    helperText: 'Search box placeholder text',
                },
                {
                    name: 'videoSrc',
                    type: 'file',
                    allowedFileTypes: ['webm', 'mp4'],
                    // https://www.builder.io/c/docs/custom-components-input-types#code-strong-file-strong-code
                    required: true,
                    defaultValue: '/home/video-homepage-hero-background.webm',
                    helperText: 'Path of background video file',
                },
                {
                    name: 'posterSrc',
                    type: 'file',
                    allowedFileTypes: ['jpg', 'png'],
                    required: true,
                    defaultValue: '/home/video-homepage-hero-background.jpg',
                    helperText: 'Path of background video file',
                },
            ],
        },
    ],
};
