import { incrementTelemetry } from 'core-events';
import React, { useContext, useEffect } from 'react';

import { NavSearchContext } from '../../context/NavSearchContext';
import { AdobePluginSuggestionVariant } from '../../types/shared';
import useMedia from '../../utils/useMedia';
import { ADOBE_PLUGIN_LANDING_PAGE_RELATIVE_PATH, ADOBE_PLUGIN_MARKETPLACE_URL } from './constants';

export interface AdobePluginSuggestionProps {
    searchTerm?: string;
    variant: AdobePluginSuggestionVariant;
    show: boolean;
    useNewSearchUI?: boolean;
}

const AdobePluginSuggestion = ({
    searchTerm = '',
    variant,
    show = false,
    useNewSearchUI = false,
}: AdobePluginSuggestionProps) => {
    const context = useContext(NavSearchContext);

    const keywords = ['after effects', 'aftereffects', 'premiere', 'premierepro', 'premiere pro', 'adobe'];

    const isValidSearchTerm = keywords.some((keyword) => {
        return keyword.startsWith(searchTerm.trim().toLowerCase()) || searchTerm.trim().toLowerCase().includes(keyword);
    });

    const ctaText =
        variant === AdobePluginSuggestionVariant.LANDING_PAGE
            ? 'Learn more about the Storyblocks Plugin for Adobe Creative Cloud'
            : 'Try the Storyblocks Plugin For Adobe Creative Cloud';

    const ctaUrl =
        variant === AdobePluginSuggestionVariant.LANDING_PAGE
            ? ADOBE_PLUGIN_LANDING_PAGE_RELATIVE_PATH
            : ADOBE_PLUGIN_MARKETPLACE_URL;

    const isSmallScreen = useMedia('(max-width: 673px)', false);

    const shouldShow = !isSmallScreen && show && isValidSearchTerm;

    useEffect(() => {
        if (shouldShow) {
            incrementTelemetry('ppro.site-hook.typeahead-display');
        }
    }, []);

    return (
        shouldShow && (
            <div className="flex flex-nowrap items-center py-4 mt-4 border-t border-b-0 border-l-0 border-r-0 border-solid border-gray-300 font-semibold">
                <img
                    alt="After Effects Icon"
                    className={useNewSearchUI ? 'w-11 lg:w-6 mr-1' : 'w-6 mr-1'}
                    src={`${context.imagesUrl}/PremiereProPlugin/After-Effects-Icon-sm.png`}
                />
                <img
                    alt="Premiere Pro Icon"
                    className={useNewSearchUI ? 'w-11 lg:w-6 mr-1' : 'w-6 mr-1'}
                    src={`${context.imagesUrl}/PremiereProPlugin/Premiere-Pro-Icon-sm.png`}
                />
                <a
                    className={`text-gray-950 ${useNewSearchUI ? 'w-44 lg:w-full mx-auto' : ''}`}
                    href={ctaUrl}
                    target="_blank"
                    onClick={() => incrementTelemetry('ppro.site-hook.typeahead-click')}
                    rel="noreferrer"
                >
                    {ctaText}
                </a>{' '}
                <div className={`ml-1 font-normal transform -rotate-45 ${useNewSearchUI ? 'mx-auto' : ''}`}>
                    {'\u2192'}
                </div>
            </div>
        )
    );
};

export default AdobePluginSuggestion;
