import React from 'react';
import { SearchFilterContentTypes } from 'stockblocks-client';
import { twMerge } from 'tailwind-merge';

import { Audio as AudioIcon, Video as VideoIcon, Image as ImageIcon } from '@videoblocks/react-icons';

const svgIcon = 'w-5 h-5 mt-0.5';
const contentTypeLabel = 'contentTypeLabel';
const contentClassIcon = 'contentClassIcon';
const allLabel = 'allLabel';

export const ContentTypes: Array<{
    label: React.ReactElement;
    value: SearchFilterContentTypes;
}> = [
    {
        label: (
            <div className={contentTypeLabel}>
                <div className={contentClassIcon}>
                    <VideoIcon className={svgIcon} />
                </div>
                <div className={allLabel}>All </div>Video
            </div>
        ),
        value: SearchFilterContentTypes.All_videos_content_type,
    },
    {
        label: (
            <div className={contentTypeLabel}>
                <div className={twMerge(contentClassIcon, 'contentTypeSelector')}>
                    <VideoIcon className={svgIcon} />
                </div>
                Footage
            </div>
        ),
        value: SearchFilterContentTypes.Footage,
    },
    {
        label: (
            <div className={contentTypeLabel}>
                <div className={twMerge(contentClassIcon, 'contentTypeSelector')}>
                    <VideoIcon className={svgIcon} />
                </div>
                Backgrounds
            </div>
        ),
        value: SearchFilterContentTypes.Motion_bgs,
    },
    {
        label: (
            <div className={contentTypeLabel}>
                <div className={twMerge(contentClassIcon, 'contentTypeSelector')}>
                    <VideoIcon className={svgIcon} />
                </div>
                Templates
            </div>
        ),
        value: SearchFilterContentTypes.Templates,
    },
    {
        label: (
            <div className={contentTypeLabel}>
                <div className={contentClassIcon}>
                    <AudioIcon className={svgIcon} />
                </div>
                <div className={allLabel}>All </div>Audio
            </div>
        ),
        value: SearchFilterContentTypes.All_audio_content_type,
    },
    {
        label: (
            <div className={contentTypeLabel}>
                <div className={twMerge(contentClassIcon, 'contentTypeSelector')}>
                    <AudioIcon className={svgIcon} />
                </div>
                Music
            </div>
        ),
        value: SearchFilterContentTypes.Music,
    },
    {
        label: (
            <div className={contentTypeLabel}>
                <div className={twMerge(contentClassIcon, 'contentTypeSelector')}>
                    <AudioIcon className={svgIcon} />
                </div>
                Sound Effects
            </div>
        ),
        value: SearchFilterContentTypes.Sound_effects,
    },
    {
        label: (
            <div className={contentTypeLabel}>
                <div className={contentClassIcon}>
                    <ImageIcon className={svgIcon} />
                </div>
                <div className={allLabel}>All </div>Images
            </div>
        ),
        value: SearchFilterContentTypes.All_images_content_type,
    },
    {
        label: (
            <div className={contentTypeLabel}>
                <div className={twMerge(contentClassIcon, 'contentTypeSelector')}>
                    <ImageIcon className={svgIcon} />
                </div>
                Photos
            </div>
        ),
        value: SearchFilterContentTypes.Photos,
    },
    {
        label: (
            <div className={contentTypeLabel}>
                <div className={twMerge(contentClassIcon, 'contentTypeSelector')}>
                    <ImageIcon className={svgIcon} />
                </div>
                Vectors
            </div>
        ),
        value: SearchFilterContentTypes.Vectors,
    },
    {
        label: (
            <div className={contentTypeLabel}>
                <div className={twMerge(contentClassIcon, 'contentTypeSelector')}>
                    <ImageIcon className={svgIcon} />
                </div>
                Illustrations
            </div>
        ),
        value: SearchFilterContentTypes.Illustrations,
    },
];

export const ContentTypesWithSnapshots: Array<{
    label: React.ReactElement;
    value: SearchFilterContentTypes;
}> = [
    ...ContentTypes.slice(0, 9),
    {
        label: (
            <div className={contentTypeLabel}>
                <div className={twMerge(contentClassIcon, 'contentTypeSelector')}>
                    <ImageIcon className={svgIcon} />
                </div>
                Snapshots
                <span className="beta-badge ml-2 px-1.5 py-0.5 rounded bg-gray-800 font-inter font-bold text-xs text-white">
                    Beta
                </span>
            </div>
        ),
        value: SearchFilterContentTypes.Snapshots,
    },
    ...ContentTypes.slice(9),
];
