import React from 'react';
import { twMerge } from 'tailwind-merge';

export default function ProminentSearchButton({
    primary,
    useNewSearchUI,
}: {
    primary: boolean;
    useNewSearchUI?: boolean;
}) {
    const btnClass = useNewSearchUI
        ? 'rounded-full border-none h-14 w-16 p-4 bg-gray-800 m-2'
        : twMerge(
              'unifiedNav-searchButton',
              'prominent-search-button',
              'bg-gray-700 border-0 rounded-r-full h-full p-3.5',
              primary && 'bg-yellow-500',
              'btn-icon',
          );

    const svgClass = twMerge('prominent-button fill-current', !primary && 'text-white');

    return (
        <button type="submit" className={btnClass} aria-label="Submit Search">
            <svg
                className={svgClass}
                width={useNewSearchUI ? '24' : '32'}
                height={useNewSearchUI ? '24' : '32'}
                viewBox="0 0 24 24"
            >
                <path
                    d="M15.9471365,14.5813852 L15.176316,14.5813852 L14.9031138,14.315539 C16.0739803,12.9370775 16.678928,11.0564621 16.3471825,9.05769289 C15.8885931,6.32046212 13.6249179,4.13461597 10.8928961,3.79984674 C6.76559165,3.28784674 3.29202103,6.79307751 3.79939651,10.9580006 C4.13114202,13.7149237 6.29724505,15.9992314 9.00975244,16.4620006 C10.9904683,16.7967698 12.8540975,16.1863083 14.2201084,15.0047698 L14.4835533,15.2804621 L14.4835533,16.0583083 L18.6303722,20.2429237 C19.0304182,20.646616 19.684152,20.646616 20.0841981,20.2429237 C20.4842442,19.8392314 20.4842442,19.179539 20.0841981,18.7758467 L15.9471365,14.5813852 Z M10.092804,14.5813852 C7.66325596,14.5813852 5.70205457,12.6023083 5.70205457,10.150616 C5.70205457,7.69892366 7.66325596,5.71984674 10.092804,5.71984674 C12.5223519,5.71984674 14.4835533,7.69892366 14.4835533,10.150616 C14.4835533,12.6023083 12.5223519,14.5813852 10.092804,14.5813852 Z"
                    id="path-1"
                />
            </svg>
        </button>
    );
}
