import isEmpty from 'lodash/isEmpty';

import { ContentCollection, TypeAheadSuggestedPage } from '../NavSearchElements/NavSearchTypes';
import { NullSuggestions } from './NullSearchDropdown';
import { RelatedContentTypeSuggestion } from './RelatedContentSuggestion';
import { WithinAssetContentTypeSuggestion } from './WithinAssetSuggestion';
import {
    KeyboardDirectionsType,
    keyboardEventsKey,
    ListDirections,
    ListDirectionsType,
    NullStateSuggestionTypes,
    suggestionTypes,
    suggestionTypesKey,
} from './constants';

export type ComputedSuggestion = {
    keywords: string[];
    relatedCollections: ContentCollection[];
    withinAssetContentTypeSuggestions: WithinAssetContentTypeSuggestion[];
    relatedContentTypeSuggestion: RelatedContentTypeSuggestion[];
    suggestedPages: TypeAheadSuggestedPage[];
};
export enum interactiveElements {
    SearchBar = 'SEARCH_BAR',
    SeeMoreCollectionLink = 'SEE_MORE_COLLECTION_LINK',
}
export type Sections = suggestionTypes | interactiveElements;
export type NullStateSections = NullStateSuggestionTypes | interactiveElements;
export type Node = Record<KeyboardDirectionsType, Sections[] | NullStateSections[] | null> &
    Record<'DIRECTION', ListDirectionsType | 'NA'>;
export type DirectedGraphType = Record<Sections, Node>;
export type NullStateDirectedGraphType = Record<NullStateSections, Node>;

export const findTheNextNodeAndIndex = (
    directedGraph: DirectedGraphType | NullStateDirectedGraphType,
    direction: KeyboardDirectionsType,
    origin: Sections | NullStateSections,
    suggestions: ComputedSuggestion | NullSuggestions,
    selectedIndex: number,
): [Sections | NullStateSections, number] => {
    // traverse the graph using breath first search, and find the first available node
    // available node being the first non empty suggestion
    let curr = origin;
    const visited = { [origin]: true };
    const queue: ListDirectionsType[] = [];
    const nextNodes: ListDirectionsType[] = directedGraph[curr][direction] ? directedGraph[curr][direction] : [];
    queue.push(...nextNodes);
    while (!isEmpty(queue)) {
        curr = queue.shift() as string;
        if (curr != null && !visited[curr]) {
            if (!(curr in suggestions)) {
                return [curr, -1];
            }
            if (!isEmpty(suggestions[curr])) {
                const listDirection = directedGraph[curr].DIRECTION;
                const newNodeAndKeyboardDirectionIsTheSame =
                    (direction === keyboardEventsKey.ARROW_UP && listDirection === ListDirections.VERTICAL) ||
                    (direction === keyboardEventsKey.ARROW_LEFT && listDirection === ListDirections.HORIZONTAL);
                const newIndex = newNodeAndKeyboardDirectionIsTheSame ? suggestions[curr].length - 1 : 0;
                return [curr, newIndex];
            }
            queue.push(...(directedGraph[curr][direction] ? directedGraph[curr][direction] : []));
            visited[curr] = true;
        }
    }

    if (!(origin in suggestions)) {
        return [origin, selectedIndex];
    }

    // handle what the next index will be based on the direction of the current list
    // and the next list
    const originListDirection = directedGraph[origin].DIRECTION;
    const originListIsVerticalButKeyboardDirectionIsHorizontal =
        originListDirection === ListDirections.VERTICAL &&
        (direction === keyboardEventsKey.ARROW_LEFT || direction === keyboardEventsKey.ARROW_RIGHT);
    const originListIsHorizontalButKeyboardDirectionIsVertical =
        originListDirection === ListDirections.HORIZONTAL &&
        (direction === keyboardEventsKey.ARROW_UP || direction === keyboardEventsKey.ARROW_DOWN);
    if (originListIsVerticalButKeyboardDirectionIsHorizontal || originListIsHorizontalButKeyboardDirectionIsVertical) {
        return [origin, selectedIndex];
    }

    return [origin, selectedIndex === 0 ? suggestions[origin].length - 1 : 0];
};

export const calculateNewIndexBasedonDirection = (selectedIndex: number, direction: KeyboardDirectionsType): number => {
    if (direction === keyboardEventsKey.ARROW_UP || direction === keyboardEventsKey.ARROW_LEFT) {
        return selectedIndex - 1;
    }
    return selectedIndex + 1;
};

export const UILayoutGraph: DirectedGraphType = {
    [interactiveElements.SearchBar]: {
        [keyboardEventsKey.ARROW_UP]: null,
        [keyboardEventsKey.ARROW_DOWN]: [suggestionTypesKey.KEYWORDS],
        [keyboardEventsKey.ARROW_LEFT]: null,
        [keyboardEventsKey.ARROW_RIGHT]: null,
        DIRECTION: 'NA',
    },
    [suggestionTypesKey.KEYWORDS]: {
        [keyboardEventsKey.ARROW_UP]: null,
        [keyboardEventsKey.ARROW_DOWN]: [suggestionTypesKey.SUGGESTED_PAGES],
        [keyboardEventsKey.ARROW_LEFT]: null,
        [keyboardEventsKey.ARROW_RIGHT]: [
            suggestionTypesKey.WITHIN_ASSET,
            suggestionTypesKey.RELATED_CONTENT_TYPE,
            suggestionTypesKey.RELATED_COLLECTION,
        ],
        DIRECTION: ListDirections.VERTICAL,
    },
    [suggestionTypesKey.WITHIN_ASSET]: {
        [keyboardEventsKey.ARROW_UP]: null,
        [keyboardEventsKey.ARROW_DOWN]: [suggestionTypesKey.RELATED_CONTENT_TYPE],
        [keyboardEventsKey.ARROW_LEFT]: [suggestionTypesKey.KEYWORDS],
        [keyboardEventsKey.ARROW_RIGHT]: null,
        DIRECTION: ListDirections.VERTICAL,
    },
    [suggestionTypesKey.RELATED_CONTENT_TYPE]: {
        [keyboardEventsKey.ARROW_UP]: [suggestionTypesKey.WITHIN_ASSET],
        [keyboardEventsKey.ARROW_DOWN]: [suggestionTypesKey.RELATED_COLLECTION],
        [keyboardEventsKey.ARROW_LEFT]: [suggestionTypesKey.KEYWORDS],
        [keyboardEventsKey.ARROW_RIGHT]: null,
        DIRECTION: ListDirections.VERTICAL,
    },
    [suggestionTypesKey.RELATED_COLLECTION]: {
        [keyboardEventsKey.ARROW_UP]: [suggestionTypesKey.RELATED_CONTENT_TYPE],
        [keyboardEventsKey.ARROW_DOWN]: [interactiveElements.SeeMoreCollectionLink],
        [keyboardEventsKey.ARROW_LEFT]: [suggestionTypesKey.KEYWORDS],
        [keyboardEventsKey.ARROW_RIGHT]: null,
        DIRECTION: ListDirections.HORIZONTAL,
    },
    [suggestionTypesKey.SUGGESTED_PAGES]: {
        [keyboardEventsKey.ARROW_UP]: [suggestionTypesKey.KEYWORDS],
        [keyboardEventsKey.ARROW_DOWN]: null,
        [keyboardEventsKey.ARROW_LEFT]: null,
        [keyboardEventsKey.ARROW_RIGHT]: [
            suggestionTypesKey.WITHIN_ASSET,
            suggestionTypesKey.RELATED_CONTENT_TYPE,
            suggestionTypesKey.RELATED_COLLECTION,
        ],
        DIRECTION: ListDirections.HORIZONTAL,
    },
    [interactiveElements.SeeMoreCollectionLink]: {
        [keyboardEventsKey.ARROW_UP]: [suggestionTypesKey.RELATED_COLLECTION],
        [keyboardEventsKey.ARROW_DOWN]: null,
        [keyboardEventsKey.ARROW_LEFT]: [suggestionTypesKey.KEYWORDS],
        [keyboardEventsKey.ARROW_RIGHT]: null,
        DIRECTION: 'NA',
    },
};

export const NullStateUIGraph: NullStateDirectedGraphType = {
    [interactiveElements.SearchBar]: {
        [keyboardEventsKey.ARROW_UP]: null,
        [keyboardEventsKey.ARROW_DOWN]: [NullStateSuggestionTypes.latestCollections],
        [keyboardEventsKey.ARROW_LEFT]: null,
        [keyboardEventsKey.ARROW_RIGHT]: null,
        DIRECTION: 'NA',
    },
    [NullStateSuggestionTypes.latestCollections]: {
        [keyboardEventsKey.ARROW_UP]: null,
        [keyboardEventsKey.ARROW_DOWN]: [NullStateSuggestionTypes.trendingCollections],
        [keyboardEventsKey.ARROW_LEFT]: null,
        [keyboardEventsKey.ARROW_RIGHT]: null,
        DIRECTION: ListDirections.HORIZONTAL,
    },
    [NullStateSuggestionTypes.trendingCollections]: {
        [keyboardEventsKey.ARROW_UP]: [NullStateSuggestionTypes.latestCollections],
        [keyboardEventsKey.ARROW_DOWN]: [interactiveElements.SeeMoreCollectionLink],
        [keyboardEventsKey.ARROW_LEFT]: null,
        [keyboardEventsKey.ARROW_RIGHT]: null,
        DIRECTION: ListDirections.HORIZONTAL,
    },
    [interactiveElements.SeeMoreCollectionLink]: {
        [keyboardEventsKey.ARROW_UP]: [NullStateSuggestionTypes.trendingCollections],
        [keyboardEventsKey.ARROW_DOWN]: null,
        [keyboardEventsKey.ARROW_LEFT]: null,
        [keyboardEventsKey.ARROW_RIGHT]: null,
        DIRECTION: 'NA',
    },
};
