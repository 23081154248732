import React from 'react';
import { twMerge } from 'tailwind-merge';

import { suggestionTypesKey } from './constants';

export interface KeywordsSuggestionProps {
    keywords?: string[];
    searchTerm?: string;
    selectedIndex?: number;
    onClick?: (value) => void;
    onHover?: (value) => void;
    onLeavingHover?: () => void;
    useNewSearchUI?: boolean;
}

const specialKeywordMatchRegex = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;

const KeywordsSuggestion = ({
    keywords = [],
    searchTerm = '',
    selectedIndex = -1,
    onClick = () => null,
    onHover = () => null,
    onLeavingHover = () => null,
    useNewSearchUI,
}: KeywordsSuggestionProps) => {
    return (
        !!keywords?.length && (
            <ul className="flex flex-col m-0 p-0 w-full" role="listbox">
                {keywords.map((keyword, index) => {
                    const cleanSearchTerm = searchTerm.replace(specialKeywordMatchRegex, '');
                    return (
                        <li
                            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                            role="button"
                            tabIndex={0}
                            id={`${suggestionTypesKey.KEYWORDS}${index}`}
                            key={index}
                            className={twMerge(
                                useNewSearchUI ? '-ml-3 pl-3 rounded-md' : 'px-4',
                                'cursor-pointer block text-sm font-normal leading-4 overflow-hidden py-2 text-ellipsis hover:bg-gray-110 w-full',
                                index === selectedIndex && useNewSearchUI && 'bg-gray-110',
                                index === selectedIndex &&
                                    !useNewSearchUI &&
                                    'bg-gray-110 border-solid border-2 border-blue-500',
                            )}
                            onClick={() => onClick(keyword)}
                            onKeyDown={() => onClick(keyword)}
                            onFocus={() => onHover(index)}
                            onMouseOver={() => onHover(index)}
                            onBlur={onLeavingHover}
                            onMouseOut={onLeavingHover}
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: keyword.replace(new RegExp(cleanSearchTerm, 'i'), '<b>$&</b>'),
                                }}
                            />
                        </li>
                    );
                })}
            </ul>
        )
    );
};

export default KeywordsSuggestion;
