import { lazyLoadDefaultConfig } from '../utils/constants';

export const config = {
    name: 'Video Card',
    inputs: [
        {
            name: 'videoSrc',
            type: 'file',
            required: false,
            allowedFileTypes: ['webm', 'mp4'],
            defaultValue: '/assets/common/videos/placeholder.webm',
            helperText: 'src url for the video when hovered',
            friendlyName: 'Video URL',
        },
        {
            name: 'videoPosterSrc',
            type: 'file',
            required: false,
            allowedFileTypes: ['jpg', 'png', 'webp'],
            defaultValue: '/assets/common/images/placeholder.png',
            helperText: 'url for the image displayed when not hovered',
            friendlyName: 'Background Image URL',
        },
        {
            name: 'videoPosterAltText',
            type: 'string',
            required: false,
            helperText: 'alt text for the image displayed when not hovered',
            friendlyName: 'Background Image Alt Text',
        },
        {
            name: 'portraitSrc',
            type: 'file',
            allowedFileTypes: ['jpg', 'png', 'webp'],
            required: false,
            helperText: "url for the creator's portrait in the lower right corner",
            friendlyName: 'Portrait Image URL',
            defaultValue: '/assets/common/images/placeholder.png',
        },
        {
            name: 'portraitAltText',
            type: 'string',
            required: false,
            helperText: 'alt text for the creator portrait',
            friendlyName: 'Portrait Image Alt Text',
            defaultValue: 'Portrait of the collection creator',
        },
        {
            name: 'href',
            type: 'string',
            required: false,
            helperText: 'url to navigate to when clicked',
            friendlyName: 'Link URL',
        },
        {
            name: 'imagePriority',
            type: 'boolean',
            defaultValue: false,
            helperText:
                'Whether to prioritize loading the image. Set this to true for the first image in a list of images or otherwise largest contentful paint',
            friendlyName: 'Image Priority',
        },
        {
            name: 'backgroundColor',
            type: 'string',
            defaultValue: 'transparent',
            helperText:
                'Background color of the card. If set, it will also change the padding of the text. Use Tailwind color names rather than hex codes.',
            friendlyName: 'Background Color',
        },
        { ...lazyLoadDefaultConfig },
        {
            name: 'title',
            type: 'string',
            required: false,
            friendlyName: 'Title',
        },
        {
            name: 'artistName',
            type: 'string',
            required: false,
            friendlyName: 'Artist Name',
        },
        {
            name: 'description',
            type: 'string',
            required: false,
            friendlyName: 'Description',
        },
    ],
};
