import { ContentClass } from './types';

export enum SearchFilterContentTypes {
    All_videos_content_type = 'all-videos',
    All_audio_content_type = 'all-audio',
    All_images_content_type = 'all-images',
    Footage = 'footage',
    Motion_bgs = 'motion-backgrounds',
    Templates = 'templates',
    Premiere_pro_templates = 'premiere-pro-templates',
    After_effects = 'after-effects-templates',
    Davinci_resolve = 'davinci-resolve-templates',
    Apple_motion = 'apple-motion-templates',
    Music = 'music',
    Sound_effects = 'sfx',
    Photos = 'photos',
    Snapshots = 'snapshots',
    Vectors = 'vectors',
    Illustrations = 'illustrations',
};

export type SearchOption = {
    id: SearchFilterContentTypes;
    label: string;
    placeholderText: string;
    videoSrc?: string;
    posterSrc?: string;
};
  
// i.e. ContentGroup.php
export interface Category {
    id: number;
    groupId: number;
    urlId: string;
    name: string;
    description: string;
    type: string;
    source: string;
    searchIndexable: boolean;
    searchType?: string;
    active: boolean;
    dateAdded: string;
    dateUpdated: string;
    contributorId?: number;
    stockContributorId?: number;
    position?: unknown;
    isHomepageCollection?: boolean;
    showInApi?: boolean;
}

// e.g. StockItemOptionViewDTO + VideoStockItemOptionsViewDTO + GraphicStockItemOptionsViewDTO
export type StockItemFormat = {
    id: string;
    label: string;
    prettyLabel: string;
    filesize: string;
    filename: string;
    resolution: string;
    fileExtension: string;
    quality: string;
    formatName: string;
    downloadAjaxUrl: string;
    downloadUrl: string;
    exportUrl: string;
    frameRate?: string;
    codec?: string;
    dpi?: number;
};

export type StockItem = {
    description: string;
    maxResolution: number;
    searchType: SearchFilterContentTypes;
    contributor: {
        portfolioLink: string;
        username: string;
    };
    isActive: boolean;
    hasRecommendedMusic: boolean;
    downloadFormats: DownloadFormat[];
    isTemplate: boolean;
    keywords: DetailsKeywords[];
    categories: Category[];
    is360: boolean;
    shouldStockItemHaveNewFlag: boolean;
    mediumImageUrl: string;
    largeImageUrl: string;
    previewUrl: string;
    smallPreviewUrl: string;
    id: number;
    title: string;
    contentType: string;
    thumbnailUrl: string;
    detailsUrl: string;
    contentClass: ContentClass;
    assetId: string;
    contentId: number;
    dateLastAccessed: string;
    ownerName: string;
    isMarketPlaceItem: boolean;
    distributionType: string;
    isSavingFavorite: boolean;
    collapsedSetId: string;
    type: string;
    mediaType: string;
    moods: Category[];
    genres: Category[];
    instruments: Category[];
    duration: number;
    numCollapses: number;
    stockLicenseTypeId: number;
    colors: Color[];
    waveformUrl: string;
    shouldStockItemHaveStaffPickFlag: boolean;
    hasVector?: boolean;
    aspectRatio?: number;
    dateAdded: string;
    snapshotVideo?: StockItem;
};

export type StockItemDetails = {
    genres?: object[];
    isFavorite: boolean;
    moods?: Category[];
    selectedAcquisitionOption?: object[];
    stockItem: StockItem;
    stockItemArtists?: object[];
    stockItemFormats?: StockItemFormat[];
    topTags?: object[];
};

export interface DownloadFormat {
    format: string;
    downloadUrl: string;
}

export interface DetailsKeywords {
    keyword: string;
    url: string;
}

export type Color = { hex_code: string; percentage: number };
