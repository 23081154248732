import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { SearchFilterContentTypes } from 'stockblocks-client';

import {
    ContentCollection,
    relatedContentTypeAndKeyword,
    searchFilterContentTypeToContentClass,
} from '../NavSearchElements/NavSearchTypes';
import AdobePluginSuggestion, { AdobePluginSuggestionProps } from './AdobePluginSuggestion';
import AutoSuggestDropdown, { AutoSuggestDropdownProps } from './AutoSuggestDropdown';
import CollectionsSuggestion, { CollectionsSuggestionProps } from './CollectionsSuggestion';
import KeywordsSuggestion, { KeywordsSuggestionProps } from './KeywordsSuggestion';
import RelatedContentSuggestion, { RelatedContentSuggestionProps } from './RelatedContentSuggestion';
import WithinAssetSuggestion, { WithinAssetSuggestionProps } from './WithinAssetSuggestion';
import { suggestionTypesKey } from './constants';
import { ComputedSuggestion } from './utils';

export type PopulatedSuggestions = {
    keywords?: string[];
    relatedCollections?: ContentCollection[];
    relatedContentTypesAndKeywords?: relatedContentTypeAndKeyword[];
};

export interface PopulatedSearchDropdownProps {
    showSuggestions?: boolean;
    autoSuggestDropdownProps?: Omit<AutoSuggestDropdownProps, 'show'>;
    keywordsSuggestionProps?: Omit<KeywordsSuggestionProps, 'keywords'>;
    collectionsSuggestionProps?: Omit<CollectionsSuggestionProps, 'collections'>;
    withinAssetSuggestionProps?: Omit<WithinAssetSuggestionProps, 'contentClass' | 'withinAssetContentTypeSuggestions'>;
    relatedContentSuggestionProps?: Omit<RelatedContentSuggestionProps, 'relatedContentTypeSuggestions'>;
    adobeSuggestionProps?: AdobePluginSuggestionProps;
    suggestions?: ComputedSuggestion;
    contentType: SearchFilterContentTypes;
    useNewSearchUI?: boolean;
}

const PopulatedSearchDropdown = ({
    showSuggestions = false,
    autoSuggestDropdownProps = {},
    keywordsSuggestionProps = {},
    collectionsSuggestionProps = {},
    withinAssetSuggestionProps = {},
    relatedContentSuggestionProps = {},
    adobeSuggestionProps,
    suggestions = {
        keywords: [],
        relatedCollections: [],
        withinAssetContentTypeSuggestions: [],
        relatedContentTypeSuggestion: [],
        suggestedPages: [],
    },
    contentType,
    useNewSearchUI,
}: PopulatedSearchDropdownProps) => {
    return (
        <AutoSuggestDropdown
            show={showSuggestions}
            className={`flex flex-col ${
                !isEmpty(suggestions?.keywords) &&
                isEmpty(suggestions?.relatedContentTypeSuggestion) &&
                isEmpty(suggestions?.withinAssetContentTypeSuggestions) &&
                isEmpty(suggestions?.relatedCollections)
                    ? 'w-full'
                    : ''
            }`}
            {...autoSuggestDropdownProps}
            useNewSearchUI={useNewSearchUI}
        >
            <div
                className={
                    useNewSearchUI
                        ? 'flex flex-col lg:flex-row gap-6 lg:gap-0 h-144 lg:h-auto overflow-y-scroll lg:overflow-visible'
                        : 'flex flex-row space-x-0.5'
                }
            >
                <div
                    className={
                        useNewSearchUI
                            ? 'flex-grow flex-shrink-0 flex flex-col mr-8 lg:w-38'
                            : 'w-32vw lg:w-61 flex-grow flex-shrink flex flex-col space-y-2'
                    }
                >
                    <KeywordsSuggestion
                        keywords={suggestions.keywords}
                        {...keywordsSuggestionProps}
                        useNewSearchUI={useNewSearchUI}
                    />
                </div>
                <div
                    className={
                        useNewSearchUI
                            ? 'flex flex-col flex-grow space-y-5'
                            : 'flex flex-col flex-grow py-5 space-y-5 pr-8'
                    }
                >
                    <WithinAssetSuggestion
                        {...withinAssetSuggestionProps}
                        contentClass={searchFilterContentTypeToContentClass[contentType]}
                        withinAssetContentTypeSuggestions={suggestions.withinAssetContentTypeSuggestions}
                        useNewSearchUI={useNewSearchUI}
                    />
                    <RelatedContentSuggestion
                        {...relatedContentSuggestionProps}
                        relatedContentTypeSuggestions={suggestions.relatedContentTypeSuggestion}
                        useNewSearchUI={useNewSearchUI}
                    />
                    <CollectionsSuggestion
                        collections={suggestions.relatedCollections}
                        title="Related Collections"
                        getTelemetryString={(routeUri) => `typeahead.collection.${routeUri}.click`}
                        idLabel={`${suggestionTypesKey.RELATED_COLLECTION}`}
                        {...collectionsSuggestionProps}
                        useNewSearchUI={useNewSearchUI}
                    />
                </div>
            </div>
            <AdobePluginSuggestion
                searchTerm={keywordsSuggestionProps.searchTerm || ''}
                variant={adobeSuggestionProps?.variant || 0}
                show={!!adobeSuggestionProps?.show}
                useNewSearchUI={useNewSearchUI}
            />
        </AutoSuggestDropdown>
    );
};

export default PopulatedSearchDropdown;
