import { typeAheadPageSuggestions } from './NavSearchConstants';
import { TypeAheadSuggestedPage } from './NavSearchTypes';

/* Search service only allows typehead for:
 * - smart quotes [“”‘’]
 * - Basic Latin Unicode block [\u0020-\u007e]
 * - Latin-1 Supplement Unicode block [\u00a0-\u00ff]
 */
const invalidCharacters = new RegExp('[^“”‘’\u0020-\u007e\u00a0-\u00ff]');

export function validateKeywords(value = '') {
    // search service rejects typeahead values less than 2 characters
    const validLength = value.trim().length > 1;
    return validLength && !invalidCharacters.test(value);
}

export const getRelatedPages = (query = '', isEnterpriseMember: boolean): TypeAheadSuggestedPage[] =>
    Object.keys(typeAheadPageSuggestions).reduce((acc, url) => {
        const { terms, shouldShowForEnterprise, ...suggestion } = typeAheadPageSuggestions[
            url
        ] as TypeAheadSuggestedPage;
        const queryInTerms = terms.some((term) => term.toLowerCase().includes(query.toLowerCase()));
        const shouldShow = isEnterpriseMember ? shouldShowForEnterprise : true;

        if (queryInTerms && shouldShow) {
            acc.push(typeAheadPageSuggestions[url]);
        }

        return acc;
    }, [] as TypeAheadSuggestedPage[]);

export function cleanSearchKeywords(keywords: string) {
    return keywords
        .replace(/[\s+]+/g, ' ')
        .replace(/[()]+/g, '')
        .trim();
}
