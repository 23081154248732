import { incrementTelemetry } from 'core-events';
import noop from 'lodash/noop';
import React, { useContext } from 'react';
import { twMerge } from 'tailwind-merge';

import { NavSearchContext } from '../../context/NavSearchContext';
import { ContentType } from '../../types/shared';
import {
    contentTypeToSearchFilterContentType,
    RelatedContentTypeLabel,
    WithinContentClassLabel,
} from '../NavSearchElements/NavSearchTypes';
import { suggestionTypesKey } from './constants';

export type WithinAssetContentTypeSuggestion = {
    keyword: string;
    contentType: string;
};

export type WithinAssetSuggestions = {
    contentClass: string;
    withinAssetContentTypeSuggestions: WithinAssetContentTypeSuggestion[];
};

export interface WithinAssetSuggestionProps {
    titleClassName?: string;
    contentClass?: string;
    withinAssetContentTypeSuggestions?: WithinAssetContentTypeSuggestion[];
    onSelect?: (value: string, contentType?: ContentType) => void;
    selectedIndex?: number;
    useNewSearchUI?: boolean;
}

const WithinAssetSuggestion = ({
    titleClassName = '',
    contentClass = '',
    withinAssetContentTypeSuggestions = [],
    onSelect = noop,
    selectedIndex = -1,
    useNewSearchUI,
}: WithinAssetSuggestionProps) => {
    const context = useContext(NavSearchContext);
    return (
        !!(contentClass && withinAssetContentTypeSuggestions?.length) && (
            <div className="flex flex-col space-y-2.5">
                <div
                    className={twMerge(
                        useNewSearchUI
                            ? 'related-collection-title text-xs border-gray-300 border-0 border-b w-full lg:min-w-38 min-w-21vw border-solid leading-5 font-normal'
                            : 'related-collection-title text-sm border-gray-500 border-0 border-b w-3/4 lg:min-w-38 min-w-21vw border-solid leading-5 font-normal',
                        titleClassName,
                    )}
                >
                    {`Within ${WithinContentClassLabel[contentClass]}`}
                </div>
                <ul className={'flex flex-col min-w-316'}>
                    {withinAssetContentTypeSuggestions.map((suggestion, index) => {
                        return (
                            <li
                                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                                role="button"
                                tabIndex={0}
                                id={`${suggestionTypesKey.WITHIN_ASSET}${index}`}
                                key={index}
                                className={twMerge(
                                    'flex cursor-pointer text-sm leading-4 list-none whitespace-nowrap py-2 items-center space-x-2 hover:bg-gray-110',
                                    useNewSearchUI && '-ml-2 pl-2 rounded-md',
                                    index === selectedIndex && useNewSearchUI && 'bg-gray-110',
                                    index === selectedIndex &&
                                        !useNewSearchUI &&
                                        'bg-gray-110 border-solid border-2 border-blue-500',
                                )}
                                onClick={() => {
                                    incrementTelemetry('typeahead.withinContentClass.click');
                                    onSelect(
                                        suggestion.keyword,
                                        contentTypeToSearchFilterContentType[suggestion.contentType],
                                    );
                                }}
                                onKeyDown={() => {
                                    incrementTelemetry('typeahead.withinContentClass.click');
                                    onSelect(
                                        suggestion.keyword,
                                        contentTypeToSearchFilterContentType[suggestion.contentType],
                                    );
                                }}
                            >
                                <svg
                                    width="8"
                                    height="10"
                                    viewBox="0 0 8 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M5.16481 9.77344L7.90348 7.46021L5.16481 5.14699V6.88634H0L0 8.03408H5.16481V9.77344Z"
                                        fill="black"
                                    />
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M3.11136e-05 -1.04904e-05L3.11136e-05 7.46027H1.14777L1.14777 -1.04904e-05H3.11136e-05Z"
                                        fill="black"
                                    />
                                </svg>
                                <div className="font-normal">
                                    <span className="font-bold">{suggestion.keyword}</span>{' '}
                                    {`in ${RelatedContentTypeLabel[suggestion.contentType]}`}
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        )
    );
};

export default WithinAssetSuggestion;
