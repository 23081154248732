import isEmpty from 'lodash/isEmpty';
import React, {
    ChangeEventHandler,
    KeyboardEventHandler,
    useCallback,
    useContext,
    useMemo,
    useRef,
    useState,
} from 'react';
import { SearchFilterContentTypes } from 'stockblocks-client';
import { twMerge } from 'tailwind-merge';
import { useDebouncedCallback } from 'use-debounce';

import { NavSearchContext } from '../../context/NavSearchContext';
import { AdobePluginSuggestionVariant } from '../../types/shared';
import { SearchOrigins } from '../../utils/constants';
import fetchTypeahead from '../../utils/fetchTypeahead';
import deferToNextFrame from '../../utils/shared';
import AdobePluginSuggestion from '../AutosuggestElements/AdobePluginSuggestion';
import AutoSuggestDropdown from '../AutosuggestElements/AutoSuggestDropdown';
import AutoSuggestInput from '../AutosuggestElements/AutoSuggestInput';
import { Suggestions } from './NavSearchTypes';
import {
    ContentCollection,
    isTypeaheadKeyword,
    isTypeAheadSuggestedPage,
    TypeAheadSuggestedPage,
} from './NavSearchTypes';
import { getRelatedPages, validateKeywords } from './searchUtils';

const specialKeywordMatchRegex = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;

function getCollectionUrl(contentType: SearchFilterContentTypes, collectionName: string) {
    switch (contentType) {
        case SearchFilterContentTypes.All_audio_content_type:
        case SearchFilterContentTypes.Music:
        case SearchFilterContentTypes.Sound_effects:
            return `/audio/collections/${collectionName}`;
        case SearchFilterContentTypes.All_videos_content_type:
        case SearchFilterContentTypes.Footage:
        case SearchFilterContentTypes.Motion_bgs:
        case SearchFilterContentTypes.Templates:
            return `/video/collections/${collectionName}`;
        case SearchFilterContentTypes.All_images_content_type:
        case SearchFilterContentTypes.Illustrations:
        case SearchFilterContentTypes.Photos:
        case SearchFilterContentTypes.Vectors:
            return `/images/collections/${collectionName}`;
        default:
            return `/images/collections/${collectionName}`;
    }
}

type Props = {
    contentType: SearchFilterContentTypes;
    onChange: (value: string) => void;
    onSubmit: (event: string, value: string, searchOrigin: string) => void;
    searchTerm: string;
    placeholder?: string;
    userHasAnySubscription: boolean;
    isProminentSearch?: boolean;
};

export default function NavSearchBox({
    contentType,
    onChange,
    onSubmit,
    searchTerm,
    placeholder,
    userHasAnySubscription,
    isProminentSearch = false,
}: Props) {
    const context = useContext(NavSearchContext);

    const [suggestions, setSuggestions] = useState<Suggestions>({
        keywords: [],
        collections: [],
        relatedPages: [],
    });

    const suggestionsFlatList = useMemo(() => {
        return [...suggestions.keywords, ...suggestions.collections, ...suggestions.relatedPages];
    }, [suggestions]);

    const { showSuggestions, setShowSuggestions } = context;
    const showSuggestionsRef = useRef(showSuggestions);
    // Keep track so we know when suggestions are fetched whether we actually want to render them.
    showSuggestionsRef.current = showSuggestions;

    const autoSuggestInputRef = useRef<HTMLInputElement>(null!);
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);

    const clearSuggestions = useCallback(() => {
        setSuggestions({
            keywords: [],
            collections: [],
            relatedPages: [],
        });
    }, []);

    const loadSuggestions = async (value = '') => {
        if (!validateKeywords(value)) {
            // search service won't be happy with the input so don't even try
            clearSuggestions();
            return;
        }

        if (!showSuggestions) {
            // cannot render suggestions (e.g., search was submitted) so no point in fetching
            return;
        }

        const data = await fetchTypeahead(contentType, value, false);
        if (showSuggestionsRef.current) {
            setSuggestions({
                keywords: data?.keywords || '',
                collections: data?.collections || [],
                relatedPages: getRelatedPages(value, context.isEnterpriseMember) || [],
            });
        } else {
            clearSuggestions();
        }
    };

    // Debounce input so we don't fire typeahead on every letter after the first.
    // Starting with 300 ms from some quick reading from:
    // https://ux.stackexchange.com/questions/95336/how-long-should-the-debounce-timeout-be
    // and https://lawsofux.com/doherty-threshold. 250 + ~150 browser delay roughly equals a 400ms delay.
    // If its been a second we'll still show suggestions even if still typing
    const handleSuggestionsFetchRequested = useDebouncedCallback(loadSuggestions, 250, { maxWait: 1000 });

    React.useEffect(() => {
        if (document.activeElement === autoSuggestInputRef.current && !context.isSearchAppLoaded()) {
            onChange(autoSuggestInputRef.current?.value);
            handleSuggestionsFetchRequested(autoSuggestInputRef.current?.value);
        }
    }, [autoSuggestInputRef.current]);

    const handleSuggestionSelected = (keyword: string, searchOriginOverride = '') => {
        clearSuggestions();
        setShowSuggestions(false);
        onSubmit(contentType, keyword, searchOriginOverride);
    };

    const onInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        const value = event.target.value;
        onChange(value);

        deferToNextFrame(() => {
            setShowSuggestions(true);
            setSelectedIndex(-1);
            handleSuggestionsFetchRequested(value);
        });
    };

    const onInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
        if (event.key === 'Enter' && selectedIndex === -1) {
            event.preventDefault();
            onChange(autoSuggestInputRef.current.value);

            deferToNextFrame(() => {
                handleSuggestionSelected(autoSuggestInputRef.current.value);
            });
            return;
        }

        if (!isEmpty(suggestionsFlatList)) {
            if (event.key === 'ArrowDown') {
                event.preventDefault();
                const newSelectedIndex = selectedIndex + 1 > suggestionsFlatList.length - 1 ? 0 : selectedIndex + 1;
                setSelectedIndex(newSelectedIndex);
                if (isTypeaheadKeyword(suggestionsFlatList[newSelectedIndex])) {
                    autoSuggestInputRef.current.value = suggestionsFlatList[newSelectedIndex] as string;
                }
            } else if (event.key === 'ArrowUp') {
                event.preventDefault();
                const newSelectedIndex = selectedIndex - 1 < 0 ? suggestionsFlatList.length - 1 : selectedIndex - 1;
                setSelectedIndex(newSelectedIndex);
                if (isTypeaheadKeyword(suggestionsFlatList[newSelectedIndex])) {
                    autoSuggestInputRef.current.value = suggestionsFlatList[newSelectedIndex] as string;
                }
            } else if (event.key === 'Enter') {
                event.preventDefault();
                if (isTypeaheadKeyword(suggestionsFlatList[selectedIndex])) {
                    onChange(autoSuggestInputRef.current.value);
                    handleSuggestionSelected(autoSuggestInputRef.current.value, SearchOrigins.TYPE_AHEAD);
                } else if (isTypeAheadSuggestedPage(suggestionsFlatList[selectedIndex])) {
                    clearSuggestions();
                    autoSuggestInputRef.current.value = '';
                    window.location.href = (suggestionsFlatList[selectedIndex] as TypeAheadSuggestedPage).routeUri;
                } else {
                    clearSuggestions();
                    autoSuggestInputRef.current.value = '';
                    const { view } = suggestionsFlatList[selectedIndex] as ContentCollection;
                    const { routeUri } = view;
                    window.location.href = getCollectionUrl(contentType, routeUri || '');
                }
            }
        }
    };

    const onInputBlur = () => {
        clearSuggestions();
    };

    const renderTitle = (title) => {
        return <h5 className="pt-2 pb-1 m-0 mb-1 mx-2 border-0 border-b border-gray-800 border-solid">{title}</h5>;
    };

    const suggestionClasses =
        'navSearchBox-autoSuggestionItem cursor-pointer block text-sm font-normal leading-8 overflow-hidden px-2 text-ellipsis hover:bg-gray-110 w-full';

    const renderKeywords = (keywords) => {
        if (!keywords.length) {
            return;
        }
        return (
            <>
                {keywords.map((keyword, index) => {
                    const cleanSearchTerm = searchTerm.replace(specialKeywordMatchRegex, '');
                    const onClick = () => {
                        autoSuggestInputRef.current.value = keyword;
                        handleSuggestionSelected(keyword, SearchOrigins.TYPE_AHEAD);
                    };

                    return (
                        <li
                            key={index}
                            role="option"
                            aria-selected={index === selectedIndex}
                            className={twMerge(suggestionClasses, index === selectedIndex && 'bg-gray-110')}
                            onClick={onClick}
                            onKeyDown={onClick}
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: keyword.replace(new RegExp(cleanSearchTerm, 'i'), '<b>$&</b>'),
                                }}
                            />
                        </li>
                    );
                })}
            </>
        );
    };

    const renderCollections = (collections, startingIndex) => {
        if (!collections.length) {
            return;
        }
        return (
            <>
                {renderTitle('Collections')}
                {collections.map((collection, index) => {
                    const listIndex = startingIndex + index;
                    const { view } = collection;
                    const { heroTitle, routeUri, directoryBackgroundImageUrl = '' } = view;

                    const collectionUrl = getCollectionUrl(contentType, routeUri);
                    const validTypeaheadThumbnailUrl = directoryBackgroundImageUrl.includes('https')
                        ? directoryBackgroundImageUrl
                        : 'https://d3g7htsbjjywiv.cloudfront.net/assets/common/images/logos/storyblocks.png';

                    return (
                        <li
                            key={collectionUrl}
                            className={twMerge(suggestionClasses, 'py-1', listIndex === selectedIndex && 'bg-gray-110')}
                        >
                            <a
                                href={collectionUrl}
                                onClick={() => clearSuggestions()}
                                className="flex items-center space-x-2"
                            >
                                <img
                                    className="w-8 h-8 mr-2 rounded-full"
                                    width={8}
                                    height={8}
                                    src={validTypeaheadThumbnailUrl}
                                    alt="Collection thumbnail"
                                />
                                {heroTitle}
                            </a>
                        </li>
                    );
                })}
            </>
        );
    };

    const renderAdobeSuggestions = () => {
        const variant = context.pproPluginEnabled
            ? AdobePluginSuggestionVariant.MARKETPLACE
            : AdobePluginSuggestionVariant.LANDING_PAGE;
        return <AdobePluginSuggestion searchTerm={searchTerm} variant={variant} show={userHasAnySubscription} />;
    };

    const renderSuggestions = () => {
        if (isEmpty(suggestionsFlatList)) {
            return;
        }

        const collectionsStartingIndex = suggestions.keywords.length;

        return (
            <ul
                className="flex flex-col m-0 p-0 w-full"
                role="listbox"
                id="navSearchBox-autoSuggestionList"
                aria-expanded={true}
            >
                {renderKeywords(suggestions.keywords)}
                {renderCollections(suggestions.collections, collectionsStartingIndex)}
                {renderAdobeSuggestions()}
            </ul>
        );
    };

    return (
        <div className="unifiedNav-searchBox w-full max-h-full items-center inline-flex">
            <div aria-haspopup="listbox" className="h-full w-full relative">
                <AutoSuggestInput
                    defaultValue={searchTerm}
                    onChange={onInputChange}
                    onKeyDown={onInputKeyDown}
                    onBlur={onInputBlur}
                    ref={autoSuggestInputRef}
                    placeholder={placeholder}
                    className={isProminentSearch ? 'text-base text-gray-900 pl-6' : ''}
                />
                <AutoSuggestDropdown
                    show={showSuggestions}
                    className={`flex ${!isEmpty(suggestionsFlatList) ? 'w-full' : ''}`}
                >
                    {renderSuggestions()}
                </AutoSuggestDropdown>
            </div>
        </div>
    );
}
