import { SearchFilterContentTypes } from 'stockblocks-client';

import { defaultContentTypes } from '../blocks/SearchHero.builder';
import { callToActionInputs, secondaryCallToActionInputs } from '../elements/CallToAction.builder';

export const config = {
    name: 'Search Hero',
    inputs: [
        {
            name: 'headingFirstLine',
            type: 'string',
            required: false,
            defaultValue: 'Tell all your best stories of the',
        },
        {
            name: 'headingSecondLine',
            type: 'string',
            required: false,
            defaultValue: 'world. Lorem ipsum dolor sit',
        },
        {
            name: 'trendingTags',
            type: 'string',
            required: false,
            defaultValue: 'Documentary,Love,Sport',
            helperText: 'Comma-separated list of trending tags',
        },
        {
            name: 'videoSrc',
            type: 'file',
            allowedFileTypes: ['webm', 'mp4'],
            required: false,
            defaultValue: '/home/video-homepage-hero-background.webm',
            helperText: 'Path of background video file',
        },
        {
            name: 'posterSrc',
            type: 'file',
            allowedFileTypes: ['jpg', 'png'],
            required: false,
            defaultValue: '/home/video-homepage-hero-background.jpg',
            helperText: 'Path of background poster file',
        },
        {
            name: 'posterAlt',
            type: 'string',
            required: false,
            defaultValue: 'Background image',
        },
        {
            name: 'contentTypes',
            type: 'list',
            required: false,
            helperText: 'List of clickable content types / options',
            defaultValue: defaultContentTypes,
            subFields: [
                {
                    name: 'id',
                    type: 'string',
                    required: true,
                    enum: Object.values(SearchFilterContentTypes),
                    defaultValue: SearchFilterContentTypes.All_videos_content_type,
                    helperText: 'URL root',
                },
                {
                    name: 'label',
                    type: 'string',
                    required: true,
                    defaultValue: '[Label]',
                    helperText: 'Text label for content type',
                },
                {
                    name: 'placeholderText',
                    type: 'string',
                    required: true,
                    defaultValue: 'Search [CONTENT] library...',
                    helperText: 'Search box placeholder text',
                },
            ],
        },
        ...callToActionInputs,
        ...secondaryCallToActionInputs,
    ],
};
