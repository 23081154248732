import { Component } from '@builder.io/sdk';

export const config: Component = {
    name: 'Site Map',

    childRequirements: {
        message: "You can only have 'Site Map Category' components as children",
        query: {
            'component.name': { $in: ['Site Map Category'] },
        },
    },

    inputs: [
        {
            name: 'heading',
            type: 'object',
            required: true,
            defaultValue: { href: '/', text: '[Heading]' },
            subFields: [
                {
                    name: 'href',
                    type: 'string',
                    required: true,
                    defaultValue: '/',
                },
                {
                    name: 'text',
                    type: 'string',
                    required: true,
                    defaultValue: '[Heading]',
                },
            ],
        },
    ],

    defaultChildren: [
        {
            '@type': '@builder.io/sdk:Element',
            component: {
                name: 'Site Map Category',
                options: {
                    heading: { href: '/', text: '[Category]' },
                    siteLinks: [{ href: '/', text: '[Link]' }],
                },
            },
        },
    ],
};
