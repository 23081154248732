import React, { PropsWithChildren } from 'react';

import { AppWindowLink } from '@videoblocks/react-icons';

import { TypeAheadSuggestedPage } from './NavSearchTypes';

const IconWrapper = ({ children }: PropsWithChildren<{}>) => (
    <div className="inline-block align-middle mr-2 -mb-1">{children}</div>
);

export enum Paths {
    Pricing = '/pricing',
}

export const typeAheadPageSuggestions: {
    [url in Paths]: TypeAheadSuggestedPage;
} = {
    [Paths.Pricing]: {
        terms: [
            '7 day free trial',
            'all payment methods',
            'buy',
            'free',
            'free trial',
            'free trial subscribe',
            'member',
            'member library',
            'member login',
            'membership',
            'online payment',
            'pay',
            'pay monthly',
            'pay online',
            'paying',
            'paying online',
            'payment',
            'payment methods',
            'payments',
            'plans',
            'price',
            'prices',
            'pricing',
            'purchase',
            'purchasing',
            'search',
            'subscribe',
            'subscription',
            'trial',
        ],
        title: 'Plans & pricing',
        routeUri: Paths.Pricing,
        directoryBackgroundImage: () => (
            <IconWrapper>
                <AppWindowLink width="2rem" height="2rem" />
            </IconWrapper>
        ),
        shouldShowForEnterprise: false,
    },
};
