import { timerTelemetry as coreEventsTimerTelemtry } from 'core-events';
import { fetchTypeahead as internalFetchTypeahead } from 'sb-shell/actions/fetchTypeahead';
import { SearchFilterContentTypes } from 'stockblocks-client';

import { ContentCollection } from '../elements/NavSearchElements/NavSearchTypes';

function getTypeaheadPath(contentType: SearchFilterContentTypes): string {
    switch (contentType) {
        case SearchFilterContentTypes.All_audio_content_type:
        case SearchFilterContentTypes.Music:
        case SearchFilterContentTypes.Sound_effects:
            return 'audio';
        case SearchFilterContentTypes.All_videos_content_type:
        case SearchFilterContentTypes.Footage:
        case SearchFilterContentTypes.Motion_bgs:
        case SearchFilterContentTypes.Templates:
        case SearchFilterContentTypes.Premiere_pro_templates:
        case SearchFilterContentTypes.After_effects:
        case SearchFilterContentTypes.Davinci_resolve:
        case SearchFilterContentTypes.Apple_motion:
            return 'video';
        case SearchFilterContentTypes.All_images_content_type:
        case SearchFilterContentTypes.Illustrations:
        case SearchFilterContentTypes.Photos:
        case SearchFilterContentTypes.Vectors:
        default:
            return 'images';
    }
}

export type TypeaheadResponse = {
    collections: ContentCollection[];
    keywords: string[];
    latestCollections: object[];
    relatedContentTypesAndKeywords: object[];
    message: string;
};

const fetchTypeahead = async (
    contentType: SearchFilterContentTypes,
    query = '',
    typeaheadEnhancementExperiment = false,
    timerTelemetry: () => Promise<void> = () => coreEventsTimerTelemtry('search.frontEnd.typeAhead'),
) => {
    const typeaheadTimer = timerTelemetry;
    const contentClass = getTypeaheadPath(contentType);
    const data = await internalFetchTypeahead(contentClass, encodeURIComponent(query));
    typeaheadTimer();

    if (typeaheadEnhancementExperiment || data?.keywords?.length) {
        return data;
    }
};

export default fetchTypeahead;
