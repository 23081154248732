import { Component } from '@builder.io/sdk';

import { AlertSeverity } from '../enums/AlertSeverityEnum';

export const config: Component = {
    name: 'Alert',
    inputs: [
        {
            name: 'severity',
            type: 'string',
            defaultValue: AlertSeverity.success,
            enum: [
                {
                    label: 'Error',
                    value: AlertSeverity.error,
                },
                {
                    label: 'Info',
                    value: AlertSeverity.info,
                },
                {
                    label: 'Neutral',
                    value: AlertSeverity.neutral,
                },
                {
                    label: 'Success',
                    value: AlertSeverity.success,
                },
                {
                    label: 'Warning',
                    value: AlertSeverity.warning,
                },
            ],
        },
        {
            name: 'icon',
            type: 'boolean',
            defaultValue: true,
        },
    ],
    defaultChildren: [
        {
            '@type': '@builder.io/sdk:Element',
            component: { name: 'Text', options: { text: 'Text goes here.' } },
        },
    ],
};
