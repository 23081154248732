import React, { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

export interface AutoSuggestDropdownProps {
    show?: boolean;
    className?: string;
    useNewSearchUI?: boolean;
}
const AutoSuggestDropdown = ({
    className = '',
    show = false,
    children,
    useNewSearchUI,
    ...rest
}: PropsWithChildren<AutoSuggestDropdownProps>) => {
    return (
        show && (
            <div
                role="button"
                tabIndex={0}
                className={twMerge(
                    useNewSearchUI
                        ? 'flex w-max bg-white rounded-3xl text-gray-900 absolute left-0 p-6 top-full z-50 mt-5 border border-gray-400 border-solid'
                        : 'flex bg-white rounded-b text-gray-900 shadow-dropdown absolute left-0 pb-1.5 top-full z-50',
                    className,
                )}
                onMouseDown={(e) => e.preventDefault()}
                {...rest}
            >
                {children}
            </div>
        )
    );
};

export default AutoSuggestDropdown;
