import React, { useCallback, useEffect, useState } from 'react';
import { SearchFilterContentTypes, SearchOption } from 'stockblocks-client';
import { twMerge } from 'tailwind-merge';

import { NavSearchContext, NavSearchContextProvider, NavSearchProps } from '../context/NavSearchContext';
import { CrossAssetNavSuggestions } from '../types/shared';
import { SearchOrigins } from '../utils/constants';
import { useMedia } from '../utils/useMedia';
import CrossAssetNavSearchBox from './NavSearchElements/CrossAssetNavSearchBox';
import NavSearchBox from './NavSearchElements/NavSearchBox';
import { cleanSearchKeywords } from './NavSearchElements/searchUtils';
import ProminentSearchButton from './ProminentSearchElements/ProminentSearchButton';
import ProminentSearchDropdown from './ProminentSearchElements/ProminentSearchDropdown';

export interface ProminentSearchProps extends NavSearchProps {
    onDropdownChange: (contentType: string) => void;
    searchTerm: string;
    prominentSearchOptions: SearchOption[];
    contentTypeFromPrevious: SearchFilterContentTypes;
    showDropDown: boolean;
    showContentToggles?: boolean;
    useNewSearchUI?: boolean;
    alignment?: 'left' | 'center';
}

export default function ProminentSearch(props: ProminentSearchProps) {
    const {
        onDropdownChange,
        onSubmit,
        userHasAnySubscription,
        prominentSearchOptions,
        contentTypeFromPrevious,
        showDropDown,
        showContentToggles = false,
        useNewSearchUI = false,
        useSnapshots = false,
        alignment = 'left',
    } = props;

    const defaultSearchOption = prominentSearchOptions?.[0]?.id ?? contentTypeFromPrevious;

    const [contentType, setContentType] = useState(defaultSearchOption ?? contentTypeFromPrevious);
    const [searchTerm, setSearchTerm] = useState(props.searchTerm);
    // Ensure searchTerm stays up to date when given a new one via props.
    useEffect(() => {
        setSearchTerm(props.searchTerm);
    }, [props.searchTerm]);

    useEffect(() => {
        setContentType(contentTypeFromPrevious);
    }, [contentTypeFromPrevious]);

    // i.e. tailwind md breakpoint https://storywind.netlify.app/?path=/story/design-grids--page#medium-672-1024px
    const isMediumScreen = useMedia('(min-width: 672px)', true);

    const handleDropdownChange = (contentType) => {
        onDropdownChange(contentType);
        setContentType(contentType);
    };

    const makeEmptySuggestions = (): Partial<CrossAssetNavSuggestions> => ({
        keywords: [],
        relatedCollections: [],
        latestCollections: [],
        trendingCollections: [],
        relatedContentTypesAndKeywords: [],
    });

    const [suggestions, setSuggestions] = useState(makeEmptySuggestions());

    const clearSuggestions = useCallback(() => setSuggestions(makeEmptySuggestions()), []);
    const handleSubmit = (
        contentTypeSearched: string = contentType,
        value: string = searchTerm,
        searchOriginOverride: string = '',
    ) => {
        const cleanedSearchKeywords = cleanSearchKeywords(value);
        const searchOrigin = searchOriginOverride || SearchOrigins.SEARCH_BAR;

        onSubmit(contentTypeSearched, cleanedSearchKeywords, searchOrigin);
    };

    //homepage hero yellow underline for all content types
    const activeUnderline = 'homepage-hero-product-active underline border-0 thick-3 offset-5 text-decor-color-primary';
    const inactiveUnderline = 'homepage-hero-product text-gray-300 border-0';

    return (
        <NavSearchContextProvider {...props}>
            <NavSearchContext.Consumer>
                {({ setShowSuggestions }) => (
                    <div
                        className={twMerge(
                            useNewSearchUI
                                ? 'flex flex-col w-full font-inter rounded-full border border-solid border-gray-400'
                                : 'flex flex-col w-full font-inter text-gray-900',
                            showContentToggles ? 'pb-5' : '',
                            alignment === 'center' && 'items-center',
                        )}
                    >
                        {showContentToggles && (
                            <div className="product m-0 flex text-white text-base font-bold grid-rows-4 pb-5 cursor-pointer">
                                {/* Contents of the product div */}
                                {prominentSearchOptions.map((link) => (
                                    <div
                                        role="button"
                                        tabIndex={0}
                                        key={link.id}
                                        className={twMerge(
                                            'text-sm pr-2 mobile-md:text-base ppro-sm:pr-4 ppro-sm:text-base md:pr-6 md:text-base lg:pr-6 lg:text-base',
                                            contentType === link.id ? activeUnderline : inactiveUnderline,
                                        )}
                                        data-value={link.id}
                                        onClick={() => handleDropdownChange(link.id)}
                                        onKeyDown={() => handleDropdownChange(link.id)}
                                    >
                                        {link.label}
                                    </div>
                                ))}
                            </div>
                        )}
                        <form
                            className={
                                useNewSearchUI
                                    ? 'border-none rounded-full lg:w-720px md:w-500px flex items-center bg-white'
                                    : 'border-none rounded-full lg:w-720px md:w-500px pl-5 flex items-center bg-white'
                            }
                            onSubmit={(event) => {
                                event.preventDefault();
                                setShowSuggestions(false);
                                handleSubmit(contentType, searchTerm);
                            }}
                        >
                            {showDropDown && (
                                <>
                                    <ProminentSearchDropdown
                                        onChange={handleDropdownChange}
                                        value={contentType}
                                        useNewSearchUI={useNewSearchUI}
                                        useSnapshots={useSnapshots}
                                        clearSuggestions={clearSuggestions}
                                    />
                                    {!useNewSearchUI && (
                                        <div>
                                            <div className="w-px h-11 bg-gray-500" />
                                        </div>
                                    )}
                                </>
                            )}
                            {/* {only serve CrossAssetNavSearchBox experience for new searchUI (search pages), otherwise continue to use legacy experiences} */}
                            {useNewSearchUI ? (
                                <CrossAssetNavSearchBox
                                    contentType={contentType}
                                    setSearchTerm={setSearchTerm}
                                    onSubmit={handleSubmit}
                                    searchTerm={searchTerm}
                                    useNewSearchUI
                                    placeholder={
                                        prominentSearchOptions.find(({ id }) => id === contentType)?.placeholderText
                                    }
                                    suggestions={suggestions}
                                    setSuggestions={setSuggestions}
                                    clearSuggestions={clearSuggestions}
                                />
                            ) : isMediumScreen ? (
                                <CrossAssetNavSearchBox
                                    contentType={contentType}
                                    setSearchTerm={setSearchTerm}
                                    onSubmit={handleSubmit}
                                    searchTerm={searchTerm}
                                    suggestions={suggestions}
                                    setSuggestions={setSuggestions}
                                    clearSuggestions={clearSuggestions}
                                    placeholder={
                                        prominentSearchOptions.find(({ id }) => id === contentType)?.placeholderText
                                    }
                                />
                            ) : (
                                <NavSearchBox
                                    contentType={contentType}
                                    onChange={setSearchTerm}
                                    onSubmit={handleSubmit}
                                    searchTerm={searchTerm}
                                    placeholder={
                                        prominentSearchOptions.find(({ id }) => id === contentType)?.placeholderText
                                    }
                                    userHasAnySubscription={userHasAnySubscription}
                                    isProminentSearch
                                />
                            )}
                            <ProminentSearchButton primary={showContentToggles} useNewSearchUI={useNewSearchUI} />
                        </form>
                    </div>
                )}
            </NavSearchContext.Consumer>
        </NavSearchContextProvider>
    );
}

ProminentSearch.defaultProps = {
    contentType: '',
    searchTerm: '',
};
