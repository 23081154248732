import { incrementTelemetry } from 'core-events';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { useContext, useState } from 'react';
import { SearchFilterContentTypes } from 'stockblocks-client';

import { NavSearchContext } from '../../context/NavSearchContext';
import { AdobePluginSuggestionVariant } from '../../types/shared';
import { SearchOrigins } from '../../utils/constants';
import { urlSegmentForContentClass } from '../../utils/shared';
import {
    computeRelatedContentTypeSuggestion,
    computeWithinAssetSuggestions,
    contentTypeToSearchFilterContentType,
    TypeAheadSuggestedPage,
} from '../NavSearchElements/NavSearchTypes';
import { getRelatedPages } from '../NavSearchElements/searchUtils';
import AutoSuggestInput from './AutoSuggestInput';
import { determineCollectionLinkContentClass } from './CollectionsSuggestion';
import NullSearchDropdown, { NullSuggestions } from './NullSearchDropdown';
import PopulatedSearchDropdown, { PopulatedSuggestions } from './PopulatedSearchDropdown';
import {
    KeyboardDirectionsConst,
    KeyboardDirectionsType,
    keyboardEventsKey,
    ListDirections,
    ListDirectionsType,
    NullStateSuggestionTypes,
    suggestionTypesKey,
} from './constants';
import {
    calculateNewIndexBasedonDirection,
    ComputedSuggestion,
    DirectedGraphType,
    findTheNextNodeAndIndex,
    interactiveElements,
    NullStateDirectedGraphType,
    NullStateSections,
    NullStateUIGraph,
    Sections,
    UILayoutGraph,
} from './utils';

export interface AutoSuggestsProps {
    suggestions: PopulatedSuggestions & NullSuggestions;
    searchTerm: string;
    showSuggestionsOnSearchTermChange: (searchTerm: string) => void;
    setSearchTerm: (searchTerm: string) => void;
    onClearSuggestions: () => void;
    onSelectSuggestions: (value: string, contentType?: string, searchOrigin?: string) => void;
    contentType: SearchFilterContentTypes;
    useNewSearchUI?: boolean;
    placeholder?: string;
}

enum AutoSuggestsState {
    ShowNonNullSearchSuggestions,
    ShowNullSearchSuggestions,
    NoSuggestions,
}

export default function AutoSuggest({
    suggestions = {
        keywords: [],
        relatedCollections: [],
        latestCollections: [],
        trendingCollections: [],
        relatedContentTypesAndKeywords: [],
    },
    searchTerm,
    setSearchTerm,
    showSuggestionsOnSearchTermChange,
    onClearSuggestions,
    onSelectSuggestions,
    contentType,
    useNewSearchUI,
    placeholder,
}: AutoSuggestsProps) {
    const context = useContext(NavSearchContext);
    const [showClearButton, setShowClearButton] = React.useState<boolean>(searchTerm.length > 0);

    const [selectedIndex, setSelectedIndex] = React.useState<number>(-1);
    const [dropDownState, setDropDownState] = React.useState<AutoSuggestsState>(AutoSuggestsState.NoSuggestions);
    // used to track keyboard navigation between suggestions
    const [currentUiSection, setCurrentUiSection] = React.useState<Sections | NullStateSections>(
        interactiveElements.SearchBar,
    );
    const autoSuggestComponentRef = React.useRef<HTMLDivElement>(null);
    const autoSuggestInputRef = React.useRef<HTMLInputElement>(null);
    const [suggestedPages, setSuggestedPages] = useState<TypeAheadSuggestedPage[]>([]);
    // used to track keyboard navigation between suggestions

    const variant = context.pproPluginEnabled
        ? AdobePluginSuggestionVariant.MARKETPLACE
        : AdobePluginSuggestionVariant.LANDING_PAGE;

    const computedSuggestion: ComputedSuggestion = React.useMemo(() => {
        const withinAssetContentTypeSuggestions =
            contentType && !isEmpty(suggestions.relatedContentTypesAndKeywords)
                ? computeWithinAssetSuggestions(suggestions.relatedContentTypesAndKeywords, contentType)
                      .withinAssetContentTypeSuggestions || []
                : [];

        const relatedContentTypeSuggestion =
            contentType && !isEmpty(suggestions.relatedContentTypesAndKeywords)
                ? computeRelatedContentTypeSuggestion(suggestions.relatedContentTypesAndKeywords, contentType)
                : [];

        return {
            keywords: suggestions.keywords ? suggestions.keywords : [],
            relatedCollections: suggestions.relatedCollections ? suggestions.relatedCollections.slice(0, 2) : [],
            withinAssetContentTypeSuggestions: withinAssetContentTypeSuggestions
                ? withinAssetContentTypeSuggestions
                : [],
            relatedContentTypeSuggestion: relatedContentTypeSuggestion ? relatedContentTypeSuggestion : [],
            suggestedPages: suggestedPages ? suggestedPages.slice(0, 1) : [],
        };
    }, [suggestions, contentType, suggestedPages]);

    const computedNullStateSuggestion: NullSuggestions = React.useMemo(() => {
        return {
            latestCollections: suggestions?.latestCollections ? suggestions.latestCollections.slice(0, 3) : [],
            trendingCollections: suggestions?.trendingCollections ? suggestions.trendingCollections.slice(0, 3) : [],
        };
    }, [suggestions]);

    const handeKeyBoardNavigation = (
        key,
        graph: DirectedGraphType | NullStateDirectedGraphType,
        suggestions: ComputedSuggestion | NullSuggestions,
        selectedIndex: number,
        origin: Sections | NullStateSections,
        listDirection: ListDirectionsType,
    ): void => {
        const listDirectionToOppositeDirections: Record<ListDirectionsType, ListDirectionsType[]> = {
            [ListDirections.VERTICAL]: [keyboardEventsKey.ARROW_LEFT, keyboardEventsKey.ARROW_RIGHT],
            [ListDirections.HORIZONTAL]: [keyboardEventsKey.ARROW_UP, keyboardEventsKey.ARROW_DOWN],
        };

        const ListDirectionOppositeKeyWhenIndexIsZero = {
            [ListDirections.VERTICAL]: keyboardEventsKey.ARROW_UP,
            [ListDirections.HORIZONTAL]: keyboardEventsKey.ARROW_LEFT,
        };

        const ListDirectionOppositeKeyWhenIndexIsAtLength = {
            [ListDirections.VERTICAL]: keyboardEventsKey.ARROW_DOWN,
            [ListDirections.HORIZONTAL]: keyboardEventsKey.ARROW_RIGHT,
        };

        if (
            !(origin in suggestions) ||
            (listDirectionToOppositeDirections[listDirection] || []).includes(key) ||
            (selectedIndex === 0 && key === ListDirectionOppositeKeyWhenIndexIsZero[listDirection]) ||
            (selectedIndex === suggestions[origin].length - 1 &&
                key === ListDirectionOppositeKeyWhenIndexIsAtLength[listDirection])
        ) {
            const [newNode, newSelectedIndex] = findTheNextNodeAndIndex(graph, key, origin, suggestions, selectedIndex);
            setCurrentUiSection(newNode);
            setSelectedIndex(newSelectedIndex);
            if (newNode === suggestionTypesKey.KEYWORDS) {
                autoSuggestInputRef.current!.value = get(suggestions, `keywords.${newSelectedIndex}`, '');
            }
        } else {
            const newSelectedIndex = calculateNewIndexBasedonDirection(selectedIndex, key);
            setSelectedIndex(newSelectedIndex);
            if (origin === suggestionTypesKey.KEYWORDS) {
                autoSuggestInputRef.current!.value = get(suggestions, `keywords.${newSelectedIndex}`, '');
            }
        }
    };

    const handleDirectionalInput = (key: KeyboardDirectionsType) => {
        const graph = dropDownState === AutoSuggestsState.ShowNullSearchSuggestions ? NullStateUIGraph : UILayoutGraph;
        const suggestionsMap =
            dropDownState === AutoSuggestsState.ShowNullSearchSuggestions
                ? computedNullStateSuggestion
                : computedSuggestion;
        handeKeyBoardNavigation(
            key,
            graph,
            suggestionsMap,
            selectedIndex,
            currentUiSection,
            graph[currentUiSection].DIRECTION,
        );
    };

    React.useEffect(() => {
        const suggestionsAllEmpty = !suggestions || Object.values(suggestions).every(isEmpty);

        if (suggestionsAllEmpty) {
            setDropDownState(AutoSuggestsState.NoSuggestions);
        } else if (!isEmpty(suggestions.keywords)) {
            setDropDownState(AutoSuggestsState.ShowNonNullSearchSuggestions);
        } else if (
            (!isEmpty(suggestions.latestCollections) || !isEmpty(suggestions.trendingCollections)) &&
            searchTerm?.length < 2
        ) {
            setDropDownState(AutoSuggestsState.ShowNullSearchSuggestions);
        } else {
            setDropDownState(AutoSuggestsState.NoSuggestions);
        }

        setCurrentUiSection(interactiveElements.SearchBar);
    }, [suggestions, searchTerm]);

    React.useEffect(() => {
        if (document.activeElement === autoSuggestInputRef.current && !context.isSearchAppLoaded()) {
            showSuggestionsOnSearchTermChange(autoSuggestInputRef.current!.value);
        }
    }, [autoSuggestInputRef.current]);

    React.useEffect(() => {
        if (autoSuggestInputRef.current) {
            autoSuggestInputRef.current.value = searchTerm;
        }
    }, [searchTerm]);

    const onInputChange = (event) => {
        if (event.target.value === '') {
            onClearSuggestions();
            setSuggestedPages([]);
            setShowClearButton(false);
        } else {
            setShowClearButton(true);
        }
        setSelectedIndex(-1);
        showSuggestionsOnSearchTermChange(event.target.value);
        if (event.target.value.length > 1) {
            setSuggestedPages(getRelatedPages(event.target.value, context.isEnterpriseMember));
        }
    };

    const onInputKeyDown = (event) => {
        if (!isEmpty(suggestions?.keywords) || dropDownState === AutoSuggestsState.ShowNullSearchSuggestions) {
            if (KeyboardDirectionsConst.includes(event.key)) {
                event.preventDefault();
                handleDirectionalInput(event.key);
            } else if (event.key === keyboardEventsKey.ESCAPE) {
                event.preventDefault();
                onInputBlur();
            } else if (event.key === keyboardEventsKey.ENTER) {
                if (
                    currentUiSection === interactiveElements.SearchBar ||
                    currentUiSection === suggestionTypesKey.KEYWORDS
                ) {
                    event.preventDefault();
                    let searchOrigin = '';
                    if (selectedIndex !== -1) {
                        incrementTelemetry(`typeahead.baseSuggestion.click`);
                        searchOrigin = SearchOrigins.TYPE_AHEAD;
                    }
                    onClearSuggestions();
                    onSelectSuggestions(autoSuggestInputRef.current!.value, contentType, searchOrigin);
                    setSuggestedPages([]);
                    setCurrentUiSection(interactiveElements.SearchBar);
                } else if (currentUiSection === suggestionTypesKey.WITHIN_ASSET) {
                    event.preventDefault();
                    onSelectWithinAssetSuggestions(selectedIndex, computedSuggestion);
                } else if (currentUiSection === suggestionTypesKey.RELATED_CONTENT_TYPE) {
                    event.preventDefault();
                    onSelectRelatedContentSuggestions(selectedIndex, computedSuggestion);
                } else if (
                    currentUiSection === suggestionTypesKey.RELATED_COLLECTION ||
                    currentUiSection === NullStateSuggestionTypes.trendingCollections ||
                    currentUiSection === NullStateSuggestionTypes.latestCollections
                ) {
                    event.preventDefault();
                    onEnterCollectionSuggestions(
                        selectedIndex,
                        { ...computedSuggestion, ...computedNullStateSuggestion },
                        currentUiSection,
                    );
                } else if (currentUiSection === suggestionTypesKey.SUGGESTED_PAGES) {
                    event.preventDefault();
                    onEnterSuggestedPage(computedSuggestion);
                } else if (currentUiSection === interactiveElements.SeeMoreCollectionLink) {
                    event.preventDefault();
                    onEnterSeeMoreLinks();
                }
            }
        }
    };

    const onInputBlur = () => {
        onClearSuggestions();
        setCurrentUiSection(interactiveElements.SearchBar);
        setSuggestedPages([]);
    };

    const onKeywordClick = (value: string) => {
        incrementTelemetry(`typeahead.baseSuggestion.click`);
        autoSuggestInputRef.current!.value = value;
        onSelectSuggestions(value, '', SearchOrigins.TYPE_AHEAD);
        onClearSuggestions();
        setSuggestedPages([]);
    };

    const onClearSearchTerm = (event: MouseEvent) => {
        event.preventDefault();
        onInputBlur();
        autoSuggestInputRef.current!.value = '';
        setShowClearButton(false);
        setSearchTerm('');
    };

    const onFocus = (event) => {
        if (event.target.value === '') {
            showSuggestionsOnSearchTermChange(event.target.value);
        }
    };

    const onSelectCrossAssetSuggestions = (data: string, contentType) => {
        autoSuggestInputRef.current!.value = data;
        onSelectSuggestions(data, contentType, SearchOrigins.TYPE_AHEAD);
        onClearSuggestions();
        setCurrentUiSection(interactiveElements.SearchBar);
        setSuggestedPages([]);
    };

    const onSelectWithinAssetSuggestions = (index: number, suggestions) => {
        const currentWithinAssetSuggestion = suggestions[suggestionTypesKey.WITHIN_ASSET][index];
        onSelectCrossAssetSuggestions(
            currentWithinAssetSuggestion.keyword,
            contentTypeToSearchFilterContentType[currentWithinAssetSuggestion.contentType],
        );
    };

    const onSelectRelatedContentSuggestions = (index: number, suggestions) => {
        const currentRelatedSuggestions = suggestions[suggestionTypesKey.RELATED_CONTENT_TYPE][index];
        onSelectCrossAssetSuggestions(
            currentRelatedSuggestions.keyword,
            contentTypeToSearchFilterContentType[currentRelatedSuggestions.contentType],
        );
    };

    const onEnterCollectionSuggestions = (index: number, suggestions, key) => {
        const currentRelatedCollections = suggestions[key][index];
        if (currentRelatedCollections.view.routeUri && currentRelatedCollections.contentClasses) {
            const contentClasses = determineCollectionLinkContentClass(currentRelatedCollections.contentClasses);
            const link = `/${urlSegmentForContentClass(contentClasses)}/collections/${
                currentRelatedCollections.view.routeUri
            }`;
            window.location.href = link;
        }
    };
    const onEnterSuggestedPage = (suggestions) => {
        const currentSugestedPage = suggestions[suggestionTypesKey.SUGGESTED_PAGES][0];
        if (currentSugestedPage.routeUri) {
            window.location.href = currentSugestedPage.routeUri;
        }
    };

    const onEnterSeeMoreLinks = () => {
        window.location.href = '/video/collections';
    };

    return (
        <div aria-haspopup="listbox" className="h-full w-full relative" ref={autoSuggestComponentRef}>
            <AutoSuggestInput
                placeholder={placeholder}
                defaultValue={searchTerm}
                onChange={onInputChange}
                onKeyDown={onInputKeyDown}
                onBlur={onInputBlur}
                onFocus={onFocus}
                showClearButton={showClearButton}
                onClearSearchTerm={onClearSearchTerm}
                ref={autoSuggestInputRef}
                aria-activedescendant={
                    currentUiSection == interactiveElements.SearchBar ? '' : `${currentUiSection}${selectedIndex}`
                }
                useNewSearchUI={useNewSearchUI}
            />
            <PopulatedSearchDropdown
                showSuggestions={dropDownState === AutoSuggestsState.ShowNonNullSearchSuggestions}
                suggestions={computedSuggestion}
                keywordsSuggestionProps={{
                    selectedIndex: currentUiSection === suggestionTypesKey.KEYWORDS ? selectedIndex : -1,
                    searchTerm: searchTerm,
                    onClick: onKeywordClick,
                }}
                withinAssetSuggestionProps={{
                    onSelect: onSelectCrossAssetSuggestions,
                    selectedIndex: currentUiSection === suggestionTypesKey.WITHIN_ASSET ? selectedIndex : -1,
                }}
                relatedContentSuggestionProps={{
                    onSelect: onSelectCrossAssetSuggestions,
                    selectedIndex: currentUiSection === suggestionTypesKey.RELATED_CONTENT_TYPE ? selectedIndex : -1,
                }}
                contentType={contentType}
                adobeSuggestionProps={{
                    variant,
                    show: context.userHasAnySubscription,
                }}
                collectionsSuggestionProps={{
                    selectedIndex: currentUiSection === suggestionTypesKey.RELATED_COLLECTION ? selectedIndex : -1,
                    seeMoreLinkFocused: currentUiSection === interactiveElements.SeeMoreCollectionLink,
                }}
                useNewSearchUI={useNewSearchUI}
            />
            <NullSearchDropdown
                showSuggestions={dropDownState === AutoSuggestsState.ShowNullSearchSuggestions}
                suggestions={{
                    latestCollections: suggestions?.latestCollections,
                    trendingCollections: suggestions?.trendingCollections,
                }}
                latestCollectionsSuggestionProps={{
                    selectedIndex: currentUiSection === NullStateSuggestionTypes.latestCollections ? selectedIndex : -1,
                }}
                trendingCollectionsSuggestionProps={{
                    selectedIndex:
                        currentUiSection === NullStateSuggestionTypes.trendingCollections ? selectedIndex : -1,
                    seeMoreLinkFocused:
                        dropDownState === AutoSuggestsState.ShowNullSearchSuggestions &&
                        currentUiSection === interactiveElements.SeeMoreCollectionLink,
                }}
                useNewSearchUI={useNewSearchUI}
            />
        </div>
    );
}
