import { Component } from '@builder.io/sdk';

import { ButtonColor, ButtonSize, ButtonVariant } from '@videoblocks/storywind';

export const config: Component = {
    name: 'Button',
    inputs: [
        {
            name: 'color',
            type: 'string',
            defaultValue: ButtonColor.Primary,
            enum: [
                {
                    label: 'Primary',
                    value: ButtonColor.Primary,
                },
                {
                    label: 'Secondary',
                    value: ButtonColor.Secondary,
                },
                {
                    label: 'Light',
                    value: ButtonColor.Light,
                },
                {
                    label: 'Pill',
                    value: ButtonColor.Pill,
                },
            ],
        },
        {
            name: 'fullWidth',
            type: 'boolean',
            defaultValue: false,
        },
        {
            name: 'size',
            type: 'string',
            defaultValue: ButtonSize.Medium,
            enum: [
                {
                    label: 'Small',
                    value: ButtonSize.Small,
                },
                {
                    label: 'Medium',
                    value: ButtonSize.Medium,
                },
                {
                    label: 'Large',
                    value: ButtonSize.Large,
                },
            ],
        },
        {
            name: 'squared',
            type: 'boolean',
            defaultValue: false,
        },
        {
            name: 'variant',
            type: 'string',
            defaultValue: ButtonVariant.Contained,
            enum: [
                {
                    label: 'Contained',
                    value: ButtonVariant.Contained,
                },
                {
                    label: 'Outlined',
                    value: ButtonVariant.Outlined,
                },
            ],
        },
    ],
    defaultChildren: [
        {
            '@type': '@builder.io/sdk:Element',
            component: { name: 'Text', options: { text: 'Text goes here.' } },
        },
    ],
};
