export enum ContentClass {
  Video = 'video',
  Audio = 'audio',
  Image = 'image',
}

export type NavCategory = {
  url: string;
  title: string;
  telemetry?: string;
  isNew?: boolean;
};

export type Subscription = {
  id: number;
  uniqueId: string;
  planId: number;
  organizationId: number;
  siteId: number;
  fullName: string;
  status: string;
  subscriptionEndDate: object;
  daysLeftOnSubscription: number;
  dateAdded: string;
  dateUpdated: string;
  isStarterBundle: boolean;
  isMakerOnly: boolean;
  isUnlimitedBundle: boolean;
  isGrowthBundle: boolean;
  isPremierePro: boolean;
  planClassification: PlanClassification;
  rolloverPlanId?: number;
  monthlyDownloadsRemaining?: number;
};

export enum PlanClassification {
  LEGACY_PLAN_CLASSIFICATION = 'Legacy',
  BASIC_PLAN_CLASSIFICATION = 'Basic',
  STARTER_PLAN_CLASSIFICATION = 'Starter',
  BUNDLE_PLAN_CLASSIFICATION = 'Bundle',
  UNLIMITED_PLAN_CLASSIFICATION = 'Unlimited',
  ENTERPRISE_PLAN_CLASSIFICATION = 'Enterprise',
  GROWTH_PLAN_CLASSIFICATION = 'Growth',
  PRO_PLAN_CLASSIFICATION = 'Pro',
}

export type User = {
    id: number;
    email: string;
    displayName: string;
  };

export type TopLevelCategories = {
    [Key in string]: TopLevelCategory;
};

export type TopLevelCategory = {
  url?: string;
  title?: string;
  items?: NavCategory[];
  newFeature?: boolean;
  categories?: {
    [categoryName: string]: CategoryWithSubs;
  };
  collections?: ContentCollection[];
};

export type ContentCollection = {
  active?: boolean;
  dateAdded?: string;
  dateUpdated?: string;
  description?: string;
  id?: number;
  name?: string;
  searchIndexable?: boolean;
  showInApi?: boolean;
  contentClasses?: ContentClass[];
  view: ContentCollectionView;
};
type ContentCollectionView = {
  routeUri?: string;
  redirectUri?: string;
  heroBgImgUrl?: string;
  heroTitle?: string;
  heroSubtitle?: string;
  description?: string;
  metaTitle?: string;
  metaDescription?: string;
  metaKeywords?: string;
  leftXsellTitle?: string;
  leftXsellLinkTitle?: string;
  leftXsellLinkUrl?: string;
  leftXsellBgImgUrl?: string;
  rightXsellTitle?: string;
  rightXsellLinkTitle?: string;
  rightXsellLinkUrl?: string;
  rightXsellBgImgUrl?: string;
  allowSeoIndex?: boolean;
  showInDirectory?: boolean;
  showInMainNavDropdown?: boolean;
  directoryBackgroundImageUrl?: string;
};
  
export type CategoryWithSubs = {
  url: string;
  subCategories: NavCategory[];
  title?: string;
  newFeature?: boolean;
  isBeta?: boolean;
};

interface MenuItem {
    url: string;
    target?: string;
    title: string;
}

interface MenuList {
    [key: string]: MenuItem;
}

interface PlanCta {
    id: string;
    url: string;
    linkText: string;
    mobileLinkText: string;
}

export interface UserData {
    // ReactState::buildAuthState
    auth: {
        isLoggedIn: boolean;
        isIpAuth: boolean;
        isMobile: boolean;
        user: User;
        subscription: Subscription | undefined;
        primarySubscription: Subscription | null;
        videoSubscription: Subscription | null;
        audioSubscription: Subscription | null;
        imageSubscription: Subscription | null;
        permissions: { [key: string]: boolean };
        featureFlags: { [key: string]: boolean };
        hasAnySubscription: boolean;
        isEnterprise: boolean;
    };
    // NavBarViewModel::getUserData
    ui: {
        nav: {
            menu: {
                top_level_categories: TopLevelCategories;
                collections: Record<ContentClass, NavCategory[]>;
                account: MenuList | undefined;
                showFavorites: boolean;
                isOpen: boolean;
                planCta: PlanCta | undefined;
                organizationName: string;
                shouldShowEnterpriseExperience: boolean;
                remainingExports: number;
                starterPlanRemainingDownloads: number;
            };
        };
    };
}

export type RightSideMenuState = {
    user: User | undefined;
    subscription?: Subscription;
    permissions?: { [key: string]: boolean };
    isLoggedIn: boolean;
    isIpAuth: boolean;
    storyboardEnabled: boolean;
    account: MenuList | undefined;
    ui?: {
        nav: {
            menu: {
                account: any; 
            };
        };
    };
    organizationName: string | undefined;
    planCta: PlanCta | undefined;
    remainingExports: number | undefined;
    shouldShowEnterpriseExperience: boolean;
    showFavorites: boolean;
    starterPlanRemainingDownloads: number | undefined;
    shouldUseSnapshotsUI: boolean;
    isUserOnGrowthPlan: boolean;
    isUserOnStarterPlan: boolean;
    userInitials: string;
};

export interface Folder {
  dateUpdated: string;
  description?: string | null;
  id: number;
  isOwnedByMe: boolean;
  isShared: boolean;
  isUpdating?: boolean;
  itemCount: number;
  name: string;
  ownerName?: string;
  thumbnailUrl: string | null;
  uniqueId: string;
  contentIds: number[];
  stockItemIds: number[];
  hasUserSubscription?: boolean;
}
