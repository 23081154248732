import { createContext, PropsWithChildren } from 'react';
import { SearchFilterContentTypes } from 'stockblocks-client';

export interface NavSearchProps {
    contentType: SearchFilterContentTypes;
    onSubmit: (event: string, value: string, searchOrigin: string) => void;
    userHasAnySubscription: boolean;
    isEnterpriseMember: boolean;
    pproPluginEnabled: boolean;
    isSearchAppLoaded: () => boolean;
    imagesUrl: string;
    showSuggestions: boolean;
    setShowSuggestions: (value: boolean) => void;
    searchOrigin?: string;
    useSnapshots?: boolean;
}

export const NavSearchContext = createContext({} as NavSearchProps);

export function NavSearchContextProvider({ children, ...props }: PropsWithChildren<NavSearchProps>) {
    return <NavSearchContext.Provider value={props}>{children}</NavSearchContext.Provider>;
}
